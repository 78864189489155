import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    text: {
        marginTop: theme.spacing(2),
    },
    marginLeft: {
        marginLeft: theme.spacing(2),
    },

    formTitle: {
        marginBottom: theme.spacing(2),
    },
    profileDiv: {
        [theme.breakpoints.down('sm')]: {
            display: 'block !important',
        },
        [theme.breakpoints.up('md')]: {
            display: 'grid !important',
            gridTemplateColumns: '300px auto',
            // gap: 20px;
            gap: '40px !important',
        },
    },
    leftColumn: {
        background: '#eff6ff',
        height: '100%',
    },
    leftColumnContent: {
        padding: '50px 30px !important',
        position: 'sticky !important',
        // top: 80px;
        // @media (max-width: 960px) {
        [theme.breakpoints.down('bg')]: {
            position: 'initial !important',
        },
    },
    rightColumn: {
        padding: '30px 40px 30px 0 !important',
        [theme.breakpoints.down('md')]: {
            padding: '30px 20px 30px 20px !important',
        },
    },
    sectionTitle: {
        marginBottom: '12px',
        fontWeight: 'bold !important',
    },
    CancelButton: {
        float: 'right',
        paddingTop: '9px',
        background: '#C4C4C4',
        border: 'none !important',
        borderRadius: '6px',
        color: 'white',
        fontWeight: '700',
        width: '99px',
        lineHeight: '1.75',
        '&:hover': {
            background: 'grey',
        },
    },
    SaveButton: {
        float: 'right',
        paddingTop: '9px',
        marginLeft: '14px',
        backgroundColor: '#71ba6c !important',
        border: 'none !important',
        borderRadius: '6px',
        color: 'white',
        fontWeight: '700',
        width: '99px',
        lineHeight: '1.75',
        '&:hover': {
            background: '#1d539d',
        },
    },
    tableButton: {
        padding: '4px',
        borderRadius: '50%',
        transition: 'background 0.3s ease',
        '&:hover': {
            background: 'lightgrey',
        },
        '&:hover:active': {
            background: '#e8e8e8',
        },
    },
    loaderContainer: {
        top: '0',
        position: 'absolute',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '99999999999999999999',
        background: '#f8f8f8',
        opacity: '.8',
    },
}));
