import React, { useState, useEffect, useRef } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { TextField, Popper, Paper, Grow, Snackbar } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'core/contexts/localization';
import { useExperiences } from 'core/contexts/experience';

export const GooglePlacesAutocomplete = ({ initialValue = '', citiesOnly = false, onSelect, parseDataToSet, locationId, addCoordinates }) => {
    const { t: translate } = useTranslation();
    const [address, setAddress] = useState(initialValue);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const { countries } = useExperiences();
    useEffect(() => {
        setAddress(initialValue ?? '');
    }, [initialValue]);

    const handleSelect = async (value, ...selection) => {
        if (value !== address) {
            try {
                const results = await geocodeByAddress(value);
                const addressComponents = results[0].address_components;
                const cityComponent = addressComponents.find((component) => component.types.includes('locality') || component.types.includes('administrative_area_level_1'));
                const countryComponent = addressComponents.find((component) => component.types.includes('country'));
                const extractedCity = cityComponent?.long_name;
                const extractedCountry = countryComponent?.short_name;
                const matchedCountry = countries?.find((country) => country.code === extractedCountry);
                const countryCode3 = matchedCountry?.code3 || null;
                const latLng = await getLatLng(results[0]);

                if (addCoordinates) {
                    addCoordinates(latLng.lng, latLng.lat);
                }

                parseDataToSet?.({
                    location: value,
                    city: formatToUNLOCODE(extractedCity),
                    country: countryCode3,
                    longitude: latLng.lng,
                    latitude: latLng.lat,
                });

                setAddress(value);

                if (onSelect) onSelect(value, ...selection);

                setOpenSnackBar(true);
            } catch (error) {
                console.error('Error al procesar la dirección:', error);
            }
        }
    };

    const formatToUNLOCODE = (value) => {
        const cityWithoutDiacritics = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return cityWithoutDiacritics.trim().replace(/\s+/g, ' ');
    };
    const handleChange = (value) => {
        if (value !== address) {
            setAddress(value);
            parseDataToSet?.({ location: value });
        }
    };
    const anchorRef = useRef(null);
    return (
        <PlacesAutocomplete
            key={locationId}
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
            // searchOptions={{
            //   types: citiesOnly ? ['(cities)'] : undefined,
            // }}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                const open = loading || !isEmpty(suggestions);

                const { width: minWidth } = anchorRef.current?.getBoundingClientRect?.() ?? {};

                return (
                    <>
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            open={openSnackBar}
                            autoHideDuration={1500}
                            onClose={() => setOpenSnackBar(false)}
                            message={translate('trip.view.destinations.added')}
                        />
                        <div ref={anchorRef}>
                            <TextField
                                style={{ width: '100%' }}
                                variant="outlined"
                                {...getInputProps({
                                    placeholder: translate('trip.view.destinations.type_address'),
                                })}
                            />
                        </div>
                        <Popper style={{ zIndex: 1500 }} open={open} anchorEl={anchorRef.current} role={undefined} transition>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                    }}
                                >
                                    <Paper
                                        style={{
                                            minWidth,
                                            marginTop: '4px',
                                            padding: '4px 8px',
                                        }}
                                    >
                                        {loading ? <div>...loading</div> : null}
                                        {suggestions.map((suggestion) => {
                                            const style = {
                                                backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                                                margin: '6px auto',
                                                maxWidth: '300px',
                                                cursor: 'pointer',
                                            };
                                            return <div {...getSuggestionItemProps(suggestion, { style })}>{suggestion.description}</div>;
                                        })}
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </>
                );
            }}
        </PlacesAutocomplete>
    );
};
