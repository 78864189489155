import { fetch, Method } from 'core/api/fetch';
import { ERRORS } from 'core/libs/constants';

export const BookingResourceService = {
    addResource: async (bookingId, resourceId) => {
        try {
            const { data } = await fetch(`/api/v1.1.1/experiencer/booking/resource`, {
                method: Method.POST,
                authenticated: true,
                data: { bookingId, resourceId },
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!data || data.error) throw ERRORS.INVALID_BOOKING;

            return data;
        } catch (err) {
            throw new Error(err);
        }
    },

    deleteResource: async (bookingId, bookingResourceId) => {
        try {
            const url = `/api/v1.1.1/experiencer/booking/${bookingId}/resource/${bookingResourceId}`;
            const response = await fetch(url, {
                method: Method.DELETE,
                authenticated: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response;
        } catch (err) {
            throw new Error(err);
        }
    },
};
