import { isEmpty } from 'core/packages/utils';
import { fetch, Method } from '../fetch';

const refreshTokenRoutes = {
    createRefreshToken: () => '/api/v1.1.1/integration/refreshToken',
    getRefreshToken: () => '/api/v1.1.1/integration/refreshToken',
    deleteRefreshToken: (id) => `/api/v1.1.1/integration/refreshToken/${id}`,
};

export const refreshTokenService = {
    createRefreshToken: async (info) => {
        try {
            const { data } = await fetch(refreshTokenRoutes.createRefreshToken(), {
                method: Method.POST,
                authenticated: true,
                data: info,
            });
            return isEmpty(data) ? {} : data;
        } catch (err) {
            throw new Error(err);
        }
    },
    getRefreshToken: async () => {
        try {
            const { data } = await fetch(refreshTokenRoutes.getRefreshToken(), {
                method: Method.GET,
                authenticated: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return data;
        } catch (err) {
            throw new Error(err);
        }
    },
    deleteRefreshToken: async (id) => {
        try {
            const { data } = await fetch(refreshTokenRoutes.deleteRefreshToken(id), {
                method: Method.DELETE,
                authenticated: true,
            });

            if (data[0] != true) {
                throw new Error('Could not delete token');
            }

            return true;
        } catch (err) {
            throw new Error(err);
        }
    },
};
