import { Grid2 } from '@mui/material';
import { GooglePlacesAutocomplete } from './components/placesAutocomplete';
import { useTranslation } from 'core/contexts/localization';

export const MeetingPoint = ({ ...props }) => {
    const { t: translate } = useTranslation();

    return (
        <>
            <Grid2 container itemAlign={'center'} xs={12} style={{ height: '100px', alignItems: 'center' }}>
                <Grid2 itemAlign={'center'} style={{ textAlign: 'center' }} size={3}>
                    <label id="slide-modal-video-label">{translate('experienceProfile.saveModal.meeting_point.title')}</label>
                </Grid2>
                <Grid2 item itemAlign={'center'} size={9}>
                    <GooglePlacesAutocomplete parseDataToSet={props.parseDataToSet} />
                </Grid2>
            </Grid2>
        </>
    );
};
