import Select from 'core/libs/core-ui/components/Input/Select';
import { useTranslation } from 'react-i18next';

const CheckoutItemSelect = ({ id, value, values, onChange = () => {}, ...props }) => {
    const { t: translate, i18n } = useTranslation();
    return (
        <Select
            id={id}
            label={translate('booking_new.add_activity')}
            values={values}
            onChange={onChange}
            menuItemProps={{
                style: {
                    color: 'black',
                    display: 'flex',
                    alignItems: 'center',
                },
            }}
            value={value}
        />
    );
};

export default CheckoutItemSelect;
