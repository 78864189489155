import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'core/contexts/localization';
import { Button, Hidden, Checkbox, Grid2, BottomNavigation, BottomNavigationAction, Divider, Tooltip } from '@mui/material';
import Layout from 'components/Layout';
import { useStyles } from './styles';
import clsx from 'clsx';
import { useExperiences } from 'core/contexts/experience';
import { useBookings } from 'core/contexts/booking';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from 'core/contexts/auth';
import ExperienceFilter from './components/ExperienceFilter';
import ResourceFilter from './components/ResourceFilter';
import SearchBox from './components/SearchBox';
import GlobalBookingsTable from './components/GlobalBookingsTable';
import CalendarPage from './components/Calendar';
import DateRangePicker from 'core/libs/core-ui/components/Input/DateRangePickerWithInput';
import { ResourceService } from 'core/api/services/resource';
import TodayIcon from '@mui/icons-material/Today';
import TableChartIcon from '@mui/icons-material/TableChart';
import AddIcon from 'assets/icons/home/add.svg';
import { ROUTES } from 'core/routing/routes';
import { green, red } from '@mui/material/colors';
import withStyles from '@mui/styles/withStyles';
import { AlertDialog, ExportForm } from '@utriper/core-ui';
import { DataportService } from 'core/api/services/dataport';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const GlobalBookings = () => {
    const classes = useStyles();
    const { t: translate } = useTranslation();

    const history = useHistory();
    let query = useQuery();

    const { getUserInfoSync } = useAuth();

    const { experiences, getExperiencesUpdated } = useExperiences();
    const { setPage, filteredExperiences, setFilteredExperiences, emailSearch, setEmailSearch, searchTerm, setSearchTerm, showPreviousBookings, setShowPreviousBookings, filteredResources, setFilteredResources, dateSearch, setDateSearch, view, setView } = useBookings();
    // const [tableView, setTableView] = useState(true);
    // const [filteredExperiences, setFilteredExperiences] = useState([]);
    const [calendarExp, setCalendarExp] = useState(0);
    const [dateFilter, setDateFilter] = useState('');
    const [open, setOpen] = useState(false);
    const filterExperiencesIdQueryParam = query.get('experiencesId');
    const experiencesId = filterExperiencesIdQueryParam
        ? filterExperiencesIdQueryParam
              .substring(1, filterExperiencesIdQueryParam.length - 1)
              .split(',')
              .map((n) => parseInt(n))
        : [];

    const buttons = [
        {
            label: translate('calendar.viewCalendar.table'),
            value: 'tableView',
            icon: <TableChartIcon />,
        },
        {
            label: translate('calendar.viewCalendar.bookings'),
            value: 'bookingView',
            icon: <TodayIcon />,
        },
        {
            label: translate('calendar.viewCalendar.availability'),
            value: 'slotView',
            icon: <TodayIcon />,
        },
    ];

    // fetch experience list
    useEffect(() => {
        getUserInfoSync()
            .then(async (usr) => {
                return await getExperiencesUpdated(usr.experiencer.id);
            })
            .then(async (exps) => {
                if (exps && exps.length > 0) {
                    const auxFilteredExperiences = [];
                    let checked = true;
                    for (let exp of exps) {
                        if (filteredExperiences.length) {
                            checked = filteredExperiences.find((filterExp) => filterExp.id === exp.id)?.checked;
                        }
                        auxFilteredExperiences.push({
                            id: exp.id,
                            name: exp.name,
                            expDetailId: exp.experienceDetailId,
                            checked,
                        });
                    }
                    setFilteredExperiences(auxFilteredExperiences);
                }
                return await ResourceService.getResources();
            })
            .then((resData) => {
                if (resData) {
                    const auxFilteredResources = [];
                    let checked = false;
                    for (let res of resData) {
                        if (filteredResources.length) {
                            checked = filteredResources.find((filterRes) => filterRes.id === res.id)?.checked;
                        }
                        auxFilteredResources.push({
                            id: res.id,
                            name: res.name,
                            checked,
                        });
                    }
                    setFilteredResources(auxFilteredResources);
                }
            });
    }, []);

    // This is only for view management
    useEffect(() => {
        if (filteredExperiences && filteredExperiences.length) {
            const checked = filteredExperiences.filter((exp) => exp.checked);
            setCalendarExp(checked);
        }
    }, [filteredExperiences]);

    useEffect(() => {
        if (dateSearch.startDate) {
            let dateString = dateSearch.startDate.toDateString();
            if (dateSearch.endDate) {
                dateString += ` - ${dateSearch.endDate.toDateString()}`;
            }
            setDateFilter(dateString);
        } else {
            setDateFilter('');
        }
    }, [dateSearch]);

    const onChangeFilteredExperiences = (index) => {
        const auxFilteredExperiences = [...filteredExperiences];
        auxFilteredExperiences[index].checked = !auxFilteredExperiences[index].checked;
        setFilteredExperiences(auxFilteredExperiences);
    };

    const onChangeAllFilteredExperiences = (checked) => {
        const auxFilteredExperiences = [...filteredExperiences].map((exp) => {
            return { ...exp, checked };
        });
        setFilteredExperiences(auxFilteredExperiences);
    };

    const getFilteredExperiencesId = useMemo(() => {
        return filteredExperiences.filter((exp) => exp.checked).map((exp) => exp.id);
    }, [filteredExperiences]);

    const getFilterExperiencesValues = useMemo(() => {
        return filteredExperiences.filter((exp) => exp.checked).map((exp) => exp.name);
    }, [filteredExperiences]);

    const onChangeFilteredResources = (index) => {
        const auxFilteredResources = [...filteredResources];
        auxFilteredResources[index].checked = !auxFilteredResources[index].checked;
        setFilteredResources(auxFilteredResources);
    };

    const onChangeAllFilteredResources = (checked) => {
        const auxFilteredResources = [...filteredResources].map((exp) => {
            return { ...exp, checked };
        });
        setFilteredResources(auxFilteredResources);
    };

    const getFilteredResourcesId = useMemo(() => {
        return filteredResources.filter((exp) => exp.checked).map((exp) => exp.id);
    }, [filteredResources]);

    const getFilterResourcesValues = useMemo(() => {
        return filteredResources.filter((exp) => exp.checked).map((exp) => exp.name);
    }, [filteredResources]);

    const onChangeEmailSearch = (event) => {
        setPage(1);
        setEmailSearch(event.target.value);
    };

    const onChangeSearch = (event) => {
        setPage(1);
        setSearchTerm(event.target.value);
    };

    const toggleOpenDialog = () => {
        setOpen(!open);
    };

    const GreenCheckbox = withStyles({
        root: {
            color: red[400],
            '&$checked': {
                color: red[600],
            },
        },
    })((props) => <Checkbox color="default" {...props} />);

    const onSubmit = (values) => {
        if (!values.fromDateTime || !values.toDateTime) {
            return;
        }

        const fromDateTime = new Date(values?.fromDateTime);
        const toDateTime = new Date(values?.toDateTime);
        const columns = values?.columns?.map((column) => {
            if (column?.name.length === 0 || typeof column?.name !== 'string') {
                return null;
            }

            const columnName = column?.name
                .split(' ')
                .map((word, index) => {
                    if (index === 0) return word.toLowerCase();

                    return word;
                })
                .join('');

            return {
                [columnName]: true,
            };
        });

        DataportService.exportBookings({
            fromDateTime: fromDateTime.toISOString(),
            toDateTime: toDateTime.toISOString(),
            columns,
        });
    };

    const manySelected = () => {
        return filteredExperiences.filter((exp) => exp.checked).length > 1;
    };
    return (
        <Layout contentClassName="content">
            <Grid2 container justifyContent="space-between" alignItems="center" spacing={2} className={classes.globalBookingsTitle}>
                <Grid2 size={{ xs: 12, sm: 10 }} container spacing={2}>
                    <Grid2>
                        <h1 style={{ display: 'inline' }}>{translate('global_bookings.title')}</h1>
                    </Grid2>
                    <Grid2>
                        {/* <div className={clsx(classes.globalBookingsFilter)}> */}
                        <ExperienceFilter experiences={filteredExperiences} onChange={onChangeFilteredExperiences} values={getFilterExperiencesValues} onChangeAll={onChangeAllFilteredExperiences} />
                        {/* </div> */}
                    </Grid2>
                    <Grid2>
                        <BottomNavigation value={view} className={classes.root} showLabels>
                            {buttons?.map((btn) => {
                                return <BottomNavigationAction label={btn?.label} icon={btn?.icon} onClick={() => setView(btn?.value)} />;
                                // }
                            })}
                            {/* <BottomNavigationAction
                                label="Table"
                                value={true}
                                icon={<TableChartIcon />}
                            /> */}
                            {/* <div hidden={manySelected()}> */}
                            {/* <Tooltip title="Disponible solo cuando se selecciona 1 experiencia">
                                <BottomNavigationAction
                                    disabled={manySelected()}
                                    label="Calendar"
                                    value={false}
                                    icon={<TodayIcon />}
                                    />
                            </Tooltip> */}
                            {/* </div> */}
                        </BottomNavigation>
                    </Grid2>
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 2 }}>
                    <Grid2 container justifyContent="flex-end">
                        <Button variant="contained" color="primary" onClick={() => history.push(ROUTES.BOOKINGS.NEW)} startIcon={<img alt="" src={AddIcon} width={20} style={{ margin: 'auto' }} />}>
                            {/* <Hidden smDown> */}
                            {translate('calendar.add_booking')}
                            {/* </Hidden> */}
                        </Button>
                    </Grid2>
                </Grid2>

                <Grid2 size={12}>
                    <Divider variant="middle" />
                </Grid2>
            </Grid2>
            {/* <Grid2 container justifyContent="flex-start" className={classes.filterContainer}>
                <Grid2 item>
                    <div className={clsx(classes.globalBookingsFilter)}>
                        <ExperienceFilter
                            experiences={filteredExperiences}
                            onChange={onChangeFilteredExperiences}
                            values={getFilterExperiencesValues}
                            onChangeAll={onChangeAllFilteredExperiences}
                        />
                    </div>
                </Grid2>
                <Grid2 item>
                    <BottomNavigation
                        value={tableView}
                        onChange={handleTableViewChange}
                        className={classes.root}
                        showLabels>
                        <BottomNavigationAction
                            label="Table"
                            value={true}
                            icon={<TableChartIcon />}
                        />
                        {/* <div hidden={manySelected()}> */}
            {/* <BottomNavigationAction
                            disabled={manySelected()}
                            label="Calendar"
                            value={false}
                            icon={<TodayIcon />}
                        />
                        {/* </div> */}
            {/*</BottomNavigation>
                </Grid2>
            </Grid2> */}

            <Grid2 container>
                <Grid2 size={12}>
                    <div hidden={view !== 'tableView'}>
                        <Grid2 container spacing={1} alignItems="center" className={classes.tableFilterContainer}>
                            <Grid2>
                                <DateRangePicker
                                    onChange={(value) => setDateSearch(value)}
                                    value={dateFilter}
                                    label={''}
                                    style={{
                                        width: dateFilter ? (dateSearch.endDate ? '22rem' : '14rem') : '5rem',
                                        marginRight: '1rem',
                                    }}
                                />
                            </Grid2>
                            <Grid2>
                                <SearchBox
                                    onChange={onChangeEmailSearch}
                                    value={emailSearch}
                                    label={'Email'}
                                    style={{
                                        width: '14rem',
                                        marginRight: '1rem',
                                    }}
                                />
                            </Grid2>
                            <Grid2>
                                <SearchBox
                                    onChange={onChangeSearch}
                                    value={searchTerm}
                                    label={translate('bookingForm.code')}
                                    style={{
                                        width: '7rem',
                                        marginRight: '1rem',
                                    }}
                                />
                            </Grid2>
                            {filteredResources && filteredResources.length ? (
                                <Grid2>
                                    <ResourceFilter resources={filteredResources} onChange={onChangeFilteredResources} values={getFilterResourcesValues} onChangeAll={onChangeAllFilteredResources} />
                                </Grid2>
                            ) : (
                                ''
                            )}
                            <Grid2>
                                <GreenCheckbox
                                    checked={!showPreviousBookings}
                                    color="#2F80ED"
                                    inputProps={{
                                        'aria-label': 'secondary checkbox',
                                    }}
                                    onClick={(e) => {
                                        setPage(1);
                                        setShowPreviousBookings(!showPreviousBookings);
                                    }}
                                />
                                <span>{translate('bookingForm.show_previous_bookings')}</span>
                            </Grid2>
                        </Grid2>

                        <div hidden={view !== 'tableView'}>
                            <Grid2 container pl={'10px'} pb={'25px'}>
                                <Button variant="contained" onClick={toggleOpenDialog}>
                                    {translate('bookingForm.exportButton')}
                                </Button>
                            </Grid2>
                        </div>

                        <GlobalBookingsTable experiencesId={getFilteredExperiencesId} email={emailSearch} bookingCode={searchTerm} showPreviousBookings={showPreviousBookings} load={true} resources={getFilteredResourcesId} dateSearch={dateSearch} />
                    </div>
                </Grid2>
                <Grid2 size={12}>
                    <div hidden={view === 'tableView'}>
                        <CalendarPage expId={calendarExp} view={view} setView={setView} />
                    </div>
                </Grid2>
            </Grid2>

            <AlertDialog open={open} handleClose={toggleOpenDialog} hideActions dialogTitle={translate('bookingForm.exportCsv')} dialogContent={<ExportForm onSubmit={onSubmit} onClose={toggleOpenDialog} />} />
        </Layout>
    );
};

export default GlobalBookings;
