// Core & CSS
import clsx from 'clsx';
import { useTranslation } from 'core/contexts/localization';
import { useStyles } from './styles';
// Material Ui

import { Grid2, IconButton, Button, TextField, Typography } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { InputBase } from '@mui/material';
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';

// React Final Form
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
// React
import { useHistory, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
// API Services
import { ExperienceInfoService } from '../../../core/api/services/ExperienceInfo/experienceInfo';
import { ExperienceInfoMediaService } from '../../../core/api/services/ExperienceInfo/media';
// import { SubExperienceInfoService } from '../../../core/api/services/ExperienceInfo/expDetail';
// Local components
import Layout from 'components/Layout';
import ExperienceData from './components/ExperienceData';
import SaveModal from '../components/SaveModal/index.js';
import AddIcon from 'assets/icons/home/add.svg';
import PriceIcon from 'assets/icons/experience/price.svg';
import TimeIcon from 'assets/icons/experience/time.svg';

import { highlights, viewModel, cleanViewModel, regexAlpha, regexAlphaNumeric, modalsTemp } from './constants';
import { SubExperience } from './components/SubExperience';
import { modalCollector, descriptionCollector, setDefaultData } from './components/Collector';
import { SnackBar } from 'components/SnackBar';
import { useModuses } from 'core/contexts/modus';
import { ROUTES } from 'core/routing/routes';
import { useAuth } from 'core/contexts/auth';
// import { Input } from 'antd';
// Notifications
import { toast } from 'react-toastify';

import { moods } from 'core/libs/import/Moods';

import TextInput from 'core/libs/core-ui/components/Input/Text';
import { useExperiences } from 'core/contexts/experience';

export const ExperienceInfoPage = () => {
    const classes = useStyles();
    const { t: translate } = useTranslation();
    const { role, userInfo, getUserInfoSync } = useAuth();
    // Modals
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElSlide, setAnchorElSlide] = useState(null);
    // Slide Modal
    const [modalView, setModalView] = useState('default');
    // Experience
    const experienceModel = JSON.parse(JSON.stringify(viewModel));
    const [experienceInfoTemp, setExperienceInfoTemp] = useState(experienceModel);

    //   const expIndex = locationParam[locationParam.length - 1];
    const { id } = useParams();
    const expIndex = parseInt(id);

    const [saveFormDisabled, setSaveFormDisabled] = useState(true);
    // Moduses
    // const { getModuses } = useModuses();
    // const { moduses } = useModuses();
    // SubExperience
    const [subExperienceBreak, setSubExperienceBreak] = useState('block');
    const [contentId, setContentId] = useState(null);
    // History Context
    const history = useHistory();
    // Slide
    const [index, setIndex] = useState(0);
    const [showLeftButton, setShowLeftButton] = useState(false);
    const [showRightButton, setShowRightButton] = useState(false);
    const [mediaArray, setMediaArray] = useState([]);
    const [newImageArray, setNewImageArray] = useState([]);
    const [newVideoUrlArray, setNewVideoUrlArray] = useState([]);
    const [imgDefault, setImgDefault] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [tempEraseImage, setTempEraseImage] = useState([]);
    const [pictureURL, setPictureUrl] = useState(null);
    // SnackBar
    const [open, setOpen] = useState(false);
    const [snackBarMsg, setSnackBarMsg] = useState('');

    const [canEdit, setCanEdit] = useState(false);

    const [originalExperienceInfo, setOriginalExperienceInfo] = useState(experienceModel);
    const [deletedImages, setDeletedImages] = useState([]);
    const { getCountry } = useExperiences();
    useEffect(() => {
        getCountry();
    }, []);

    // const { TextArea } = Input;

    // Mount component
    // if (expIndex === '0') { experienceInfoTemp.expDetail = [] }

    useEffect(() => {
        getUserInfoSync();
    }, []);

    useEffect(() => {
        if (index === 0) {
            setShowLeftButton(false);
        }
        if (index === mediaArray.length - 1) {
            setShowRightButton(false);
        }
    }, [index]);

    useEffect(() => {
        // getModuses();
        if (expIndex === 0) {
            // parseDataToSet({ dataDefault: cleanViewModel(viewModel) });

            // FIX THIS BEFORE COMMIT
            //   parseDataToSet({ dataDefault: _.cloneDeep(viewModel) });
            //   setOriginalExperienceInfo(_.cloneDeep(viewModel));

            setRefresh(!refresh);
            setCanEdit(true);
        } else {
            // Load experience
            ExperienceInfoService.get(expIndex).then((expData) => {
                if (!userInfo.experiencer || !userInfo.experiencer.id || expData.experiencerId != userInfo.experiencer.id) {
                    setCanEdit(false);
                } else {
                    setCanEdit(role != 'triper');
                }
                parseDataToSet({ loadData: expData });
                setSaveFormDisabled(false);
                Object.keys(modalsTemp).map((key) => {
                    modalsTemp[key] = [];
                });
                Object.keys(expData.envPolicies).map((key, index) => {
                    if (!(Object.keys(expData.envPolicies).length === index)) {
                        modalsTemp.envPolicies.push({
                            status: expData.envPolicies[key],
                        });
                    }
                });
                Object.keys(expData.include).map((key, index) => {
                    if (!(Object.keys(expData.include).length === index)) {
                        modalsTemp.include.push({
                            status: expData.include[key],
                        });
                    }
                });
                expData.modus.map((elem, index) => {
                    modalsTemp.modus.push(elem);
                });
                if (expData.media.length > 0) {
                    if (expData.media.length > 1) setShowRightButton(true);
                    setMediaArray(expData.media);
                }
            });
        }
    }, [userInfo]);

    // Data Collector
    const parseDataToSet = ({ ...data }) => {
        // Validate input
        if (Object.keys(data)[0] === 'objName') {
            descriptionCollector(data, experienceInfoTemp);
        } else if (data.subExperience) {
            setExperienceInfoTemp(experienceInfoTemp);
        } else if (data.modalData) {
            modalCollector(data.modalData, experienceInfoTemp);
        } else if (data.dataDefault) {
            // let newData = setDefaultData(data.dataDefault, viewModel);
            let newData = data.dataDefault;
            setExperienceInfoTemp(newData);
        } else if (data.loadData) {
            setExperienceInfoTemp(data.loadData);
            setPictureUrl(data.loadData.pictureURL);
            setOriginalExperienceInfo(data.loadData);
            if (!(data.loadData.media.length === 0)) {
                setMediaArray(data.loadData.media);
                setImgDefault(false);
            }
        } else if (data.location && !data.meetingPoint) {
            experienceInfoTemp.location = data.location;
            experienceInfoTemp.city = data.city;
            experienceInfoTemp.country = data.country;
            if (data.latitude && data.longitude) {
                experienceInfoTemp.latitude = data.latitude;
                experienceInfoTemp.longitude = data.longitude;
            }
        } else if (data.meetingPoint) {
            experienceInfoTemp.meetingPoint = data.meetingPoint;
        } else if (data.error) {
            experienceInfoTemp.location = '';
        }
    };
    //Image Handler
    const onSelectFile = (e) => {
        const isImage = e.target ? true : false;
        if (isImage) {
            let file = e.target.files[0];

            let fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                const byteCharacters = atob(fileReader.result.split(',')[1]);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'image/*' });
                let newImageUrl = URL.createObjectURL(blob);
                let tempmediaArray = mediaArray;
                tempmediaArray.push({ image: newImageUrl });
                setImgDefault(false);
                setMediaArray(tempmediaArray);
                setRefresh(!refresh);
                //Set array to model
                const parseFileToModel = () => {
                    return {
                        id: mediaArray.length,
                        experienceId: expIndex,
                        src: newImageUrl,
                        fileName: file.name,
                        description: file.name,
                        alt: file.name,
                        originalName: file.name,
                        type: file.type,
                        encoding: '7bit',
                        mimetype: file.type,
                        size: file.size,
                    };
                };
                let newTempImageArray = newImageArray;
                newTempImageArray.push(file);
                setNewImageArray(newTempImageArray);
                if (mediaArray.length >= 2) {
                    if (!showRightButton) {
                        setShowRightButton(true);
                    }
                }
            };
            fileReader.onerror = () => {
                toast.error(translate('experienceProfile.slideModal.error'), {
                    position: 'top-right',
                    theme: 'colored',
                });
            };
        } else {
            const parseInput = (input) => {
                let newUrl = '';
                const baseUrl = 'https://www.youtube.com/embed/';
                const case1 = input.split('watch?v=')[1];
                const case2 = input.split('youtu.be/')[1];
                const case3 = input.split('youtube.com/shorts/')[1];
                if (case1) {
                    newUrl = `${baseUrl}${case1.split('&')[0]}`;
                } else if (case2) {
                    newUrl = `${baseUrl}${case2.split('?')[0]}`;
                } else if (case3) {
                    newUrl = `${baseUrl}${case3.split('?')[0]}`;
                }
                return newUrl;
            };
            let newVideoUrl = parseInput(e);
            let tempUrlVideo = newVideoUrlArray;
            tempUrlVideo.push(newVideoUrl);
            setNewVideoUrlArray(tempUrlVideo);
            let tempmediaArray = mediaArray;
            tempmediaArray.push({ videoUrl: newVideoUrl });
            setImgDefault(false);
            setMediaArray(tempmediaArray);
            setRefresh(!refresh);
            setAnchorElSlide(false);
            if (mediaArray.length >= 2) {
                if (!showRightButton) {
                    setShowRightButton(true);
                }
            }
        }
    };
    // Slide Modal
    const SlideModal = () => {
        const open = Boolean(anchorElSlide);
        const id = open ? 'simple-popover' : undefined;
        const ViewContent = () => (
            <>
                <Grid2 itemAlign={'center'} size={12}>
                    <p id="slide-modal-message" className={classes.smMessage}>
                        {translate('experienceProfile.slideModal.content')}
                    </p>
                </Grid2>
                <Grid2 size={{ xs: 10, sm: 8 }} itemAlign={'center'} className={classes.smFooter} justifyContent="space-evenly">
                    <Grid2 size={6}>
                        <Button
                            onClick={() => {
                                setModalView('video');
                            }}
                            variant="contained"
                            type="submit"
                            className={classes.smButton}
                        >
                            {translate('experienceProfile.slideModal.videoButton')}
                        </Button>
                    </Grid2>
                    <Grid2 size={6}>
                        <Button
                            onClick={() => {
                                document.getElementById('upload-input').click();
                            }}
                            variant="contained"
                            className={classes.smButton}
                        >
                            {translate('experienceProfile.slideModal.photoButton')}
                        </Button>
                    </Grid2>
                </Grid2>
            </>
        );
        const VideoUrl = () => (
            <>
                <Grid2 container itemAlign={'center'} size={12}>
                    <Grid2 itemAlign={'center'} style={{ textAlign: 'center' }} size={3}>
                        <label id="slide-modal-video-label" style={{ lineHeight: '100px' }}>
                            Youtube URL
                        </label>
                    </Grid2>
                    <Grid2 itemAlign={'center'} size={9} style={{ paddingTop: 26 }}>
                        <TextField id="video-url" variant="outlined" />
                    </Grid2>
                </Grid2>
                <Grid2 size={8} itemAlign={'center'} className={classes.smVideoFooter}>
                    <Button
                        onClick={() => {
                            setModalView('photo');
                        }}
                        variant="contained"
                        className={classes.smVideoSaveButton}
                    >
                        {translate('experienceProfile.saveModal.cancelButton')}
                    </Button>
                    <Button
                        onClick={() => {
                            onSelectFile(document.getElementById('video-url').value);
                            setModalView('load');
                        }}
                        variant="contained"
                        type="submit"
                        className={classes.smVideoCancelButton}
                    >
                        {translate('experienceProfile.saveModal.saveButton')}
                    </Button>
                </Grid2>
            </>
        );
        return (
            <Popover
                style={{ width: '100%' }}
                id={id}
                open={open}
                anchorEl={anchorElSlide}
                onClose={handleCloseSlide}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Grid2 itemAlign={'center'} className={classes.slideModal} size={12}>
                    <label id="save-modal-title" className={classes.smTitle}>
                        {translate(`experienceProfile.slideModal.title`)}
                    </label>
                    <Divider className={classes.smDivider}></Divider>
                    {modalView === 'video' ? VideoUrl() : ViewContent()}
                </Grid2>
            </Popover>
        );
    };
    // File Uploader
    const FileUploader = () => {
        return (
            <div className={classes.modalTrigger}>
                <Button className={classes.addImageButton} onClick={() => setAnchorElSlide(true)}>
                    <img className={classes.fileAddButton} src={AddIcon} />
                    <input
                        id="upload-input"
                        style={{ opacity: 0, width: '0px', height: '0px' }}
                        type="file"
                        aria-label="add"
                        accept="image/png, image/jpeg"
                        onChange={(e) => {
                            onSelectFile(e);
                            setAnchorElSlide(false);
                        }}
                    />
                </Button>
            </div>
        );
    };
    // Erase Image
    const eraseImage = async (mediaId) => {
        try {
            await ExperienceInfoMediaService.mediaDelete(expIndex, mediaId);
            toast.success('Imagen eliminada exitosamente');
        } catch (error) {
            console.error('Error al eliminar la imagen:', error);
            toast.error('Error al eliminar la imagen. Intenta de nuevo más tarde.');
        }
    };

    const handleDeleteImage = (indexToDelete) => {
        const mediaId = mediaArray[indexToDelete]?.id;
        const updatedTempArray = mediaArray.filter((_, index) => index !== indexToDelete);
        setMediaArray(updatedTempArray);
        if (mediaId) {
            setDeletedImages((prev) => [...prev, mediaId]);
        }

        if (indexToDelete >= updatedTempArray.length) {
            setIndex(updatedTempArray.length - 1);
        }

        if (updatedTempArray.length === 0) {
            setImgDefault(true);
            setIndex(0);
        }
    };

    const handleSave = () => {
        deletedImages.forEach((mediaId) => {
            eraseImage(mediaId);
        });

        setDeletedImages([]);
    };

    // Slide Navigation
    const onPrevClick = () => {
        if (index > 0) {
            setIndex(index - 1);
        }
        if (!showRightButton) {
            setShowRightButton(true);
        }
    };
    const onNextClick = () => {
        if (index < mediaArray.length - 1) {
            setIndex(index + 1);
        }
        if (!showLeftButton) {
            setShowLeftButton(true);
        }
    };
    // Slide image array
    const sliderContent = (values) => {
        if (imgDefault) {
            return <img alt="experience" className={classes.image} src={'https://www.logistec.com/wp-content/uploads/2017/12/placeholder.png'} />;
        } else {
            return (
                Array.isArray(values) &&
                values.map((media, imgI) => {
                    if (!(media.videoUrl === undefined)) {
                        const videoUrl = media.videoUrl;
                        return (
                            <div
                                style={{
                                    width: '100%',
                                    height: '500px',
                                    overflow: 'hidden',
                                }}
                                aria-hidden={false}
                            >
                                <iframe className={classes.iframe} name={media.id} src={videoUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <div
                                    style={{
                                        position: 'relative',
                                        width: '100%',
                                        height: '1px',
                                        marginTop: '-500px',
                                    }}
                                >
                                    {role !== 'triper' && canEdit ? (
                                        <Button id={`delete-video-${imgI}`} className={classes.deleteSrc} onClick={() => handleDeleteImage(imgI)}>
                                            X
                                        </Button>
                                    ) : null}
                                </div>
                            </div>
                        );
                    } else {
                        const dynamicUrl = () => {
                            if (media.image.split('static').length > 1) {
                                // TODO : Review this url, also this should be CDN!
                                return `${process.env.REACT_APP_API_URL}${media.image}`;
                            } else {
                                return media.image;
                            }
                        };
                        return (
                            <div
                                style={{
                                    width: '100%',
                                    height: '500px',
                                    overflow: 'hidden',
                                }}
                            >
                                <img alt="experience" className={classes.image} name={media.id} src={dynamicUrl()} />
                                <div
                                    style={{
                                        position: 'relative',
                                        width: '100%',
                                        height: '500px',
                                        marginTop: '-500px',
                                    }}
                                >
                                    {media.image === pictureURL ? (
                                        <Button className={classes.setCover}>{translate('experienceProfile.coverActive')}</Button>
                                    ) : (
                                        <Button
                                            onClick={() => {
                                                const newPictureUrl = media.image;
                                                setPictureUrl(newPictureUrl);
                                                setExperienceInfoTemp({
                                                    ...experienceInfoTemp,
                                                    pictureURL: newPictureUrl,
                                                });
                                            }}
                                            className={classes.notCover}
                                        >
                                            {translate('experienceProfile.coverInactive')}
                                        </Button>
                                    )}
                                    {role !== 'triper' && canEdit ? (
                                        <Button id={`delete-img-${imgI}`} className={classes.deleteSrc} onClick={() => handleDeleteImage(imgI)}>
                                            X
                                        </Button>
                                    ) : null}
                                </div>
                            </div>
                        );
                    }
                })
            );
        }
    };
    // Slide Modal close Handler
    const handleCloseSlide = () => {
        setModalView('photo');
        setAnchorElSlide(false);
    };
    // SnackBar Handlers
    const handleClick = () => {
        setOpen(true);
    };
    const handleCloseSb = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    // Main Form Handler Submit
    const handleSubmit = (e) => {
        if (expIndex === 0) {
            // SubExperiences
            const updateArray = [];
            const saveArray = [];
            // FIRST-SAVE / POST SERVICE
            ExperienceInfoService.save(expIndex, experienceInfoTemp).then((res) => {
                // experienceInfoTemp.expDetail.map((elem) => {
                //   if (elem.id) {
                //     updateArray.push(elem);
                //   } else {
                //     saveArray.push(elem);
                //   }
                // });
                if (res[0].data) {
                    const response = res[0];
                    if (response.errors[0]?.code >= 0) {
                        toast.error(translate('experienceProfile.errors.creation'), {
                            position: 'top-right',
                            theme: 'colored',
                        });
                        handleClick();
                    } else {
                        //Sub Experiences
                        // SubExperienceInfoService.update(res[0].data[0].id, updateArray);
                        // SubExperienceInfoService.save(res[0].data[0].id, saveArray);
                        //Media
                        // Youtube
                        if (newVideoUrlArray[0]) {
                            newVideoUrlArray.map((elem) => {
                                ExperienceInfoMediaService.videoSave(res[0].data[0].id, {
                                    url: elem,
                                });
                            });
                        }
                        //Images
                        if (newImageArray[0]) {
                            newImageArray.map((elem) => {
                                ExperienceInfoMediaService.imageSave(res[0].data[0].id, elem);
                            });
                        }
                        toast.success(translate('experienceProfile.success.creation'), {
                            position: 'top-right',
                            theme: 'colored',
                        });
                        handleClick();
                        setTimeout(() => history.push(`/experience/${res[0].data[0].id}`), 1500);
                    }
                } else {
                    toast.error(translate('experienceProfile.errors.server'), {
                        position: 'top-right',
                        theme: 'colored',
                    });
                }
                handleClick();
            });
        } else {
            // UPDATE / PATCH SERCVICE
            // SubExperience data split
            const updateArray = [];
            const saveArray = [];
            // experienceInfoTemp.expDetail.map((elem) => {
            //   if (elem.id) {
            //     updateArray.push(elem);
            //   } else {
            //     saveArray.push(elem);
            //   }
            // });
            // SubExperienceInfoService.update(expIndex, updateArray);
            // SubExperienceInfoService.save(expIndex, saveArray);
            //Media
            // Youtube
            if (newVideoUrlArray[0]) {
                newVideoUrlArray.map((elem) => {
                    ExperienceInfoMediaService.videoSave(expIndex, {
                        url: elem,
                    });
                });
            }
            //Images
            if (newImageArray[0]) {
                newImageArray.forEach((elem) => {
                    ExperienceInfoMediaService.imageSave(expIndex, elem);
                });
            }

            // Experience Info
            ExperienceInfoService.update(expIndex, experienceInfoTemp).then((res) => {
                if (res[0].data) {
                    const response = res[0];
                    if (response.errors[0]?.code >= 0) {
                        toast.error(translate('experienceProfile.errors.creation'), {
                            position: 'top-right',
                            theme: 'colored',
                        });
                    } else {
                        toast.success(translate('experienceProfile.success.creation'), {
                            position: 'top-right',
                            theme: 'colored',
                        });
                    }
                } else {
                    toast.error(translate('experienceProfile.errors.server'), {
                        position: 'top-right',
                        theme: 'colored',
                    });
                }
                handleClick();
                setTimeout(() => history.push(`${ROUTES.EXPERIENCE.EDITEXPERIENCE}/${expIndex}`), 1500);
            });
            tempEraseImage.map((mediaId) => {
                ExperienceInfoMediaService.mediaDelete(expIndex, mediaId);
            });
            handleSave();
        }
    };
    // Save Modal Handler
    const saveModalHandler = (comesFromCancelPolicies = false) => {
        const aux = experienceInfoTemp;
        if (comesFromCancelPolicies) {
            aux.cancelPolicies = experienceInfoTemp.cancelPolicies;
        } else {
            aux.cancelPolicies = originalExperienceInfo.cancelPolicies;
        }

        // FIX THIS BEFORE COMMIT
        // setOriginalExperienceInfo(_.cloneDeep(aux));
        setAnchorEl(false);
    };
    // Save Modal close Handler
    const handleClose = (contentId) => {
        if (!(contentId === 'saveForm')) {
            switch (contentId) {
                case '2':
                    experienceInfoTemp.modus = modalsTemp.modus;
                    break;
                case '3':
                    Object.keys(experienceInfoTemp.envPolicies).map((key, index) => {
                        if (modalsTemp.envPolicies[index]?.status === true) {
                            experienceInfoTemp.envPolicies[key] = true;
                        } else {
                            if (!(key === 'otherDescription')) {
                                experienceInfoTemp.envPolicies[key] = false;
                            }
                        }
                    });
                    break;
                case '4':
                    break;
                case '5':
                    Object.keys(experienceInfoTemp.include).map((key, index) => {
                        if (modalsTemp.include[index]?.status === true) {
                            experienceInfoTemp.include[key] = true;
                        } else {
                            experienceInfoTemp.include[key] = false;
                        }
                    });
                    break;
                default:
                    break;
            }
        }
        setAnchorEl(false);
    };

    // Form input change handler
    const handleInputChange = (e) => {
        const saveFormButton = document.getElementById('save-form');
        const saveFormClass = `${saveFormButton.className.split('Mui-d')[0]}`;
        const saveFormDisabledClass = `${saveFormButton.className} Mui-disabled`;
        const { name, value } = e.target;
        const objName = name.split('-')[0];
        // if (name.split('-')[1] === 'alpha') {
        // if (!regexAlpha.test(value)) {
        //   e.target.style.borderBottom = '1px solid red';
        //   saveFormButton.className = saveFormDisabledClass;
        // } else {
        //   e.target.style.borderBottom = 'none';
        //   parseDataToSet({ objName, value });
        //   saveFormButton.className = saveFormClass;
        // }
        // } else {
        //   if (!regexAlphaNumeric.test(value)) {
        //     e.target.style.borderBottom = '1px solid red';
        //     saveFormButton.className = saveFormDisabledClass;
        //   } else {
        //     e.target.style.borderBottom = 'none';
        //     saveFormButton.className = saveFormClass;
        //     parseDataToSet({ objName, value });
        //   }
        // }
        e.target.style.borderBottom = 'none';
        saveFormButton.className = saveFormClass;
        parseDataToSet({ objName, value });
    };
    // Form childs
    const ExperienceTitle = ({}) => <InputBase disabled={!canEdit} name="name-alphaNumeric-title" defaultValue={experienceInfoTemp?.name} className={classes.experienceTitle} placeholder={translate('experienceProfile.title')} inputProps={{ 'aria-label': 'naked' }} onChange={handleInputChange} />;
    const ExperienceSubTitle = ({}) => <InputBase disabled={!canEdit} name="description1-alphaNumeric-subtitle" defaultValue={experienceInfoTemp?.description1} className={classes.experienceSubTitle} placeholder={`${translate('experienceProfile.subTitle')}`} inputProps={{ 'aria-label': 'naked' }} onChange={handleInputChange} />;
    const ExperienceDescription = ({}) => (
        <textarea
            disabled={!canEdit}
            style={!canEdit ? { color: 'rgba(0, 0, 0, 0.38)' } : {}}
            name="description2-alphaNumeric-description"
            // maxlength={300}
            defaultValue={experienceInfoTemp?.description2}
            // onKeyDown={(e) => {
            //   const keyCode = e.which;
            //   if (keyCode === 13 && !e.shiftKey) {
            //     e.preventDefault();
            //     return false;
            //   }
            // }}
            className={classes.experienceDescription}
            placeholder={`${translate('experienceProfile.description')}`}
            onChange={handleInputChange}
        ></textarea>
    );
    const ExperiencePrice = ({}) => (
        <InputBase
            disabled={!canEdit}
            name="price"
            defaultValue={experienceInfoTemp?.price}
            className={classes.experiencePrice}
            placeholder={translate('experienceProfile.price')}
            inputProps={{ 'aria-label': 'naked' }}
            onChange={handleInputChange}
            type="number"
            // This is to avoid '-' and negative numbers
            onKeyPress={(e) => {
                if (e.charCode === 45) {
                    e.preventDefault();
                }
            }}
        />
    );

    const ExperienceDuration = ({}) => {
        return <InputBase disabled={!canEdit} name="duration" defaultValue={experienceInfoTemp?.duration} className={classes.experiencePrice} placeholder={translate('experienceProfile.duration')} inputProps={{ 'aria-label': 'naked' }} onChange={handleInputChange} />;
    };

    return (
        <Layout>
            <Grid2 style={{ marginTop: '60px' }} container>
                <Form
                    key={'experience-info'}
                    onSubmit={handleSubmit}
                    mutators={{
                        ...arrayMutators,
                    }}
                    initialValues={experienceInfoTemp}
                    render={({ handleSubmit }) => (
                        <form key={'experience-info-form'} onSubmit={handleSubmit}>
                            <Grid2 container className={classes.expInfoContainer}>
                                <Grid2 className={classes.imageContainer}>
                                    <div className={classes.buttonsOverlay}>
                                        {showLeftButton && (
                                            <IconButton onClick={onPrevClick} size="medium" className={clsx(classes.imageButton, classes.imageLeftButton)}>
                                                <ChevronLeft fontSize="large" />
                                            </IconButton>
                                        )}
                                        {showRightButton && (
                                            <IconButton onClick={onNextClick} size="medium" className={clsx(classes.imageButton, classes.imageRightButton)}>
                                                <ChevronRight fontSize="large" />
                                            </IconButton>
                                        )}
                                    </div>
                                    <SwipeableViews
                                        sm={1}
                                        xs={12}
                                        size={{ sm: 1, xs: 12 }}
                                        style={{
                                            marginLeft: '24px',
                                            marginRight: '24px',
                                            borderRadius: '15px',
                                            height: '500px',
                                        }}
                                        containerStyle={{
                                            height: '500px',
                                            marginLeft: '-1px',
                                            marginRight: '-1px',
                                        }}
                                        index={index}
                                        onChangeIndex={setIndex}
                                        enableMouseEvents
                                    >
                                        {sliderContent(mediaArray)}
                                    </SwipeableViews>
                                    {role != 'triper' && canEdit ? <FileUploader /> : null}
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6 }} className={classes.infoContainer}>
                                    <Field name="experienceTitle-field" component={ExperienceTitle} />
                                    <Field name="experienceSubTitle" component={ExperienceSubTitle} />
                                    <Field name="experienceDescription" component={ExperienceDescription} />
                                    <Grid2 container>
                                        <Grid2
                                            size={{ xs: 12, md: 6 }}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <div className={classes.locationIcon}>
                                                <img
                                                    onClick={() => {
                                                        if (!(role !== 'triper' && canEdit)) {
                                                            return;
                                                        }
                                                        setContentId(highlights(translate)[1].id);
                                                        setAnchorEl(true);
                                                    }}
                                                    alt="label"
                                                    style={{
                                                        maxWidth: 50,
                                                        maxHeight: 50,
                                                        marginBottom: '24px',
                                                        marginTop: '24px',
                                                    }}
                                                    src={highlights(translate)[1].icon}
                                                />
                                            </div>
                                            <Typography
                                                style={{
                                                    color: '#7A7585',
                                                    marginLeft: '0.5rem',
                                                }}
                                                align="left"
                                            >
                                                {experienceInfoTemp?.location ?? highlights(translate)[1].label}
                                            </Typography>
                                        </Grid2>
                                        <Grid2
                                            size={{ xs: 12, md: 6 }}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                // justifyContent: 'center',
                                            }}
                                        >
                                            {/* <div className={classes.locationIcon}>
                                                <img
                                                    onClick={() => {
                                                        if (!(role !== 'triper' && canEdit)) {
                                                            return;
                                                        }
                                                        setContentId(highlights(translate)[2].id);

                                                        setAnchorEl(true);
                                                    }}
                                                    alt="label"
                                                    style={{
                                                        maxWidth: 50,
                                                        maxHeight: 50,
                                                        marginBottom: '24px',
                                                        marginTop: '24px',
                                                        display: 'flex',
                                                        justifySelf: 'center',
                                                        cursor: 'pointer',
                                                    }}
                                                    src={highlights(translate)[2].icon}
                                                />
                                            </div>
                                            <Typography
                                                style={{
                                                    color: '#7A7585',
                                                }}
                                                align="left"
                                            >
                                                {experienceInfoTemp?.meetingPoint ?? highlights(translate)[2].label}
                                            </Typography> */}
                                        </Grid2>
                                        <Grid2
                                            size={6}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <div className={classes.locationIcon}>
                                                <img
                                                    alt="label"
                                                    style={{
                                                        maxWidth: 50,
                                                        maxHeight: 50,
                                                        marginBottom: '24px',
                                                        marginTop: '24px',
                                                    }}
                                                    src={PriceIcon}
                                                />
                                            </div>
                                            <Field
                                                style={{
                                                    color: '#7A7585',
                                                    marginLeft: '0.5rem',
                                                    maxWidth: '150px',
                                                }}
                                                name="price"
                                                component={ExperiencePrice}
                                                type="number"
                                            />
                                        </Grid2>
                                        <Grid2
                                            size={6}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <div className={classes.locationIcon}>
                                                <img
                                                    alt="label"
                                                    style={{
                                                        maxWidth: 40,
                                                        maxHeight: 40,
                                                        marginBottom: '24px',
                                                        marginTop: '24px',
                                                        marginRight: '6px',
                                                    }}
                                                    src={TimeIcon}
                                                />
                                            </div>
                                            <Field
                                                style={{
                                                    color: '#7A7585',
                                                    marginLeft: '0.5rem',
                                                    maxWidth: '150px',
                                                }}
                                                name="duration"
                                                component={ExperienceDuration}
                                            />
                                        </Grid2>
                                    </Grid2>
                                </Grid2>
                            </Grid2>
                            <Grid2 container>
                                {highlights(translate)
                                    .slice(3)
                                    .map((highlight) => (
                                        <ExperienceData
                                            disabled={!(role !== 'triper' && canEdit)}
                                            {...highlight}
                                            modalHandlers={{
                                                setContentId: setContentId,
                                                handleClose: handleClose,
                                                setAnchorEl: setAnchorEl,
                                            }}
                                            location={experienceInfoTemp?.location}
                                        />
                                    ))}
                                {role !== 'triper' && canEdit ? (
                                    <Grid2 container alignItems="center" justifyContent="flex-end">
                                        <Grid2>
                                            <Button
                                                id="save-form"
                                                variant="contained"
                                                className={classes.submitButton}
                                                onClick={() => {
                                                    setAnchorEl(true);
                                                    setContentId('saveForm');
                                                }}
                                            >
                                                {translate('experienceProfile.buttons.save')}
                                            </Button>
                                            <SaveModal handleSubmit={handleSubmit} saveModalHandler={saveModalHandler} handleClose={handleClose} anchorEl={anchorEl} contentId={contentId} moduses={Object.values(moods)} experienceInfoTemp={experienceInfoTemp} parseDataToSet={parseDataToSet} originalExperienceInfo={originalExperienceInfo} setSnackbarMsg={setSnackBarMsg} openSnackbar={setOpen} />
                                            {/* <SnackBar
                                                msg={snackBarMsg}
                                                handleClose={handleCloseSb}
                                                handleClick={handleClick}
                                                open={open}
                                            /> */}
                                            <SlideModal />
                                        </Grid2>
                                    </Grid2>
                                ) : null}
                            </Grid2>
                        </form>
                    )}
                />
            </Grid2>
        </Layout>
    );
};
ExperienceInfoPage.displayName = 'ExperienceInfoPage';
