import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid2, useMediaQuery } from '@mui/material';
import CapacityIcon from 'assets/icons/capacity.svg';
import FullCalendar from 'core/libs/core-ui/components/Fullcalendar';
import Layout from 'components/Layout';

import { ExperienceScheduleForm } from './ExperienceScheduleForm';
import { useCalendarPage } from './hook';
import { useExperiences } from 'core/contexts/experience';
import { useAuth } from 'core/contexts/auth';
import { ExperienceInfoService } from 'core/api/services/ExperienceInfo/experienceInfo';

import { MoneyFormattedUtils } from 'core/libs/core-ui/utils/moneyFormatted';

const CalendarPage = () => {
    const history = useHistory();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const { getUserInfoSync, role } = useAuth();
    const { experience, setExperience, experienceSchedule, setExperienceSchedule, getExperienceSchedule } = useExperiences();
    const [calendarRange, setCalendarRange] = useState({});

    const {
        date: { value, onChange },
        calendar: { start, end, range, ref, onSelect, onDismiss, onSave, tz, setStart, setEnd, setTz },
        popOver: { show, position, clickedEventValues, clickedSchedule },
        onEventClick,
    } = useCalendarPage();

    const getEvents = async () => {
        if (calendarRange.start && calendarRange.end) {
            const res = await getExperienceSchedule(calendarRange);
            if (!res && !experience.id) {
                try {
                    const expNum = window.location.href.split('/');
                    const expId = parseFloat(expNum[expNum.length - 1]);
                    const expData = await ExperienceInfoService.get(expId);
                    const userData = await getUserInfoSync();
                    if (!userData.experiencer || !userData.experiencer.id || parseInt(expData.experiencerId) !== parseInt(userData.experiencer.id) || role !== 'experiencer') {
                        throw new Error('Cannot edit another user experience!');
                    }
                    if (expData) {
                        setExperience(expData);
                    } else {
                        throw new Error('Invalid experience!');
                    }
                } catch (error) {
                    alert(error);
                    history.replace('/home');
                }
            }
        }
    };

    useEffect(() => {
        getEvents();
    }, [experience, calendarRange]);

    const renderEventContent = (eventInfo) => {
        if (eventInfo.timeText.endsWith('- 23:59')) {
            eventInfo.timeText = eventInfo.timeText.replace('- 23:59', '- 00:00');
        } else if (eventInfo.timeText.endsWith('- 0')) {
            eventInfo.timeText = eventInfo.timeText.replace('- 0', '- 00:00');
        }

        return (
            <div style={{ padding: 5 }}>
                <div>
                    <b>
                        <Grid2 container justifyContent="space-between" style={{ flexDirection: 'column' }}>
                            <Grid2>
                                {eventInfo.event.extendedProps?.currency} {MoneyFormattedUtils(eventInfo.event.extendedProps?.price)}
                            </Grid2>
                            <Grid2>
                                <img src={CapacityIcon} width={20} style={{ marginRight: 5 }} />
                                {eventInfo.event.extendedProps?.capacity} {eventInfo.event.extendedProps?.privateSlot ? 'priv' : ''}
                            </Grid2>
                        </Grid2>
                    </b>
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <b>{eventInfo.timeText}</b>
                </div>
            </div>
        );
    };

    return (
        <Layout>
            <div>
                <FullCalendar useCalendarPage={useCalendarPage} schedules={experienceSchedule} renderEventContent={renderEventContent} setCalendarRange={setCalendarRange} value={value} onChange={onChange} title={experience.name} tz={tz} setTz={setTz} start={start} end={end} range={range} ref={ref} onSelect={onSelect} onDismiss={onDismiss} isSmall={isSmall} onEventClick={onEventClick}>
                    <ExperienceScheduleForm
                        start={start}
                        end={end}
                        setStart={setStart}
                        setEnd={setEnd}
                        onSelect={onSelect}
                        range={range}
                        // experienceData={experienceData ? experienceData : null}
                        open={show}
                        onDismiss={onDismiss}
                        experienceSchedule={[...experienceSchedule]}
                        onSave={({ data, event }) => {
                            const events = [...experienceSchedule];
                            switch (event) {
                                case 'onSave':
                                    if (data.repeat) {
                                        getEvents();
                                    } else {
                                        events.push({
                                            id: data.id,
                                            experienceScheduleId: data.id,
                                            start: data.startUTC,
                                            end: data.endUTC,
                                            extendedProps: data,
                                        });
                                        setExperienceSchedule([...events]);
                                    }
                                    break;
                                case 'onUpdate':
                                    if (data.repeat && data.all) {
                                        getEvents();
                                    } else {
                                        const ev = events.find((e) => e.id === data.id);
                                        ev.start = data.startUTC;
                                        ev.end = data.endUTC;
                                        ev.extendedProps = data;
                                        setExperienceSchedule(events);
                                        if (data.until) {
                                            getEvents();
                                        }
                                    }
                                    break;
                                case 'onDelete':
                                    if (data.repeat && data.all) {
                                        getEvents();
                                    } else {
                                        const index = events.findIndex((e) => e.id === data.id);
                                        if (index !== -1) {
                                            events.splice(index, 1);
                                        }
                                        setExperienceSchedule(events);
                                    }
                                    break;
                                default:
                                    break;
                            }

                            onSave();
                        }}
                        position={position}
                        expId={experience.id}
                        // subExpId={subExpId}
                        clickedEventValues={clickedEventValues}
                        clickedSchedule={clickedSchedule}
                        getEvents={getEvents}
                        calendarTz={tz}
                    />
                </FullCalendar>
            </div>
        </Layout>
    );
};

CalendarPage.displayName = 'CalendarPage';
export default CalendarPage;
