import { refreshTokenService } from 'core/api/services/refreshToken';
import React, { useContext, useState } from 'react';

const RefreshTokenContext = React.createContext(null);

export const RefreshTokenProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState([]);

    const getRefreshToken = async () => {
        try {
            setLoading(true);
            const tokenData = await refreshTokenService.getRefreshToken();
            if (!tokenData) {
                throw new Error();
            }
            setToken(tokenData.tokens);
            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const createRefreshToken = async (data) => {
        try {
            setLoading(true);
            const tokenData = await refreshTokenService.createRefreshToken(data);
            if (!tokenData) {
                throw new Error();
            }
            await getRefreshToken();
            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const deleteRefreshToken = async (id) => {
        try {
            setLoading(true);
            const tokenData = await refreshTokenService.deleteRefreshToken(id);
            await getRefreshToken();
            setLoading(false);
        } catch (err) {
            throw new Error(err);
        }
    };

    return (
        <RefreshTokenContext.Provider
            value={{
                loading,
                token,
                getRefreshToken,
                createRefreshToken,
                deleteRefreshToken,
            }}
        >
            {children}
        </RefreshTokenContext.Provider>
    );
};

RefreshTokenProvider.displayName = 'RefreshTokenProvider';

export const useRefreshToken = () => useContext(RefreshTokenContext);
