import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { toast } from 'react-toastify';

import { Grid2, Button, Tooltip, Box, Typography } from '@mui/material';
import CheckoutItemSelect from './components/CheckoutItemSelect';
import TextInput from '../Input/Text';
import { useStripe } from 'core/contexts/stripe';
import { useTranslation } from 'react-i18next';
import PaymentPlatform from 'core/libs/types/PaymentPlatform/PaymentPlatform.mjs';
import CheckoutForm from 'modules/Bookings/components/BookingNew/components/StripeForm';
import { ROUTES } from 'core/routing/routes';

import Title from 'core/libs/core-ui/components/Title';
import Dialog from 'core/libs/core-ui/components/Dialog';
import { MoneyFormattedUtils } from 'core/libs/core-ui/utils/moneyFormatted';
import CheckboxComponent from 'core/libs/core-ui/components/Input/Checkbox';
import SelectComponent from 'core/libs/core-ui/components/Input/Select';
import PhoneNumber from 'core/libs/core-ui/components/Input/PhoneNumber';
import { DataGrid } from '@utriper/core-ui';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { TIME_ZONES } from 'core/libs/core-ui/utils/dateTimeFormatted';
import { PaymentLinkModal } from '@utriper/core-ui';
import { PayNow } from '@utriper/core';

// CheckoutPageComponent: Function to handle a checkout
// Este componente deberia tener su propio contexto
const CheckoutPageComponent = ({
    totalContracts = [],
    totalItems = [],
    contract,
    handleSelectContract = () => {},
    addBooking = () => {},
    addContractBookingPayment = () => {},
    cancelStatusPaymentLink = () => {},
    deleteContractBookingPayment = () => {},
    //translate = (t, d) => d || t,
    setSelectedExperienceItem,
    handleClickAvailabilityPopper,
    selectedSlot,
    quantity,
    setQuantity,
    onCloseAvailabilityModal,
    selectedExperienceItem,
    datePicker,
    resourceSelected,
    rawDataCategories,
    useAuth,
    setCheckoutItems,
    addCheckoutItem,
    setBookingCode,
    setBookingId,
    bookingId,
    bookingCode,
    handleModalSuccess,
    handleClickResourcesModal,
    ...props
}) => {
    const history = useHistory();

    const { stripePromise } = useStripe();

    const { t: translate, i18n } = useTranslation();

    const { getUserInfoSync } = useAuth();
    const [paymentPlatformId, setPaymentPlatformId] = useState(null);
    const [stripeOpen, setStripeOpen] = useState(false);
    const [stripeClientSecret, setStripeClientSecret] = useState(false);
    const [open, setOpen] = useState(false);

    const [itemsGrid, setItemsGrid] = useState([]);
    const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
    const [paymentMethodSelected, setPaymentMethodSelected] = useState(false);
    //const [isPaymentMethodEnabled, setIsPaymentMethodEnabled] = useState(true);
    const [paymentId, setPaymentId] = useState(null);
    const [terminalPaymentId, setTerminalPaymentId] = useState(null);
    const [payNowPrice, setPayNowPrice] = useState(null);
    const [payTotalPrice, setPayTotalPrice] = useState(null);
    const [isPendingTour, setIsPendingTour] = useState(false);
    const [currency, setCurrency] = useState(null);
    const [totalPrice, setTotalPrice] = useState(0);
    const [paymentLink, setPaymentLink] = useState(null);
    // const [paymentPlatforms, setPaymentPlatforms] = useState(
    //     initialPaymentPlatforms
    // );
    const [overrideAvailability, setOverrideAvailability] = useState(false);

    const [acceptedPaymentPlatforms, setAcceptedPaymentPlatforms] = useState([]);
    const [email, setEmail] = useState('');

    useEffect(() => {
        getUserInfoSync().then(async (usr) => {
            if (usr.experiencer) {
                setAcceptedPaymentPlatforms(usr.experiencer.acceptedPaymentPlatforms);
            }
        });
    }, []);

    useEffect(() => {
        setItemsGrid([]);
    }, [contract]);

    const handleOpenAvailabilityComponent = (e) => {
        setSelectedExperienceItem({ ...e });
        handleClickAvailabilityPopper();
    };

    const handleOpenResourcesComponent = (e) => {
        setSelectedExperienceItem({ ...e });
        handleClickResourcesModal();
    };

    const handleSelectedExperience = async (e) => {
        // setQuantity(1);
        let rowKey = Date.now();
        const newItem = {
            ...e,
            experienceId: e?.experienceId,
            experience: e?.name,
            experiencer: e?.experiencer || e?.experiencerId,
            experienceDetailId: e?.experienceDetailId,
            quantity: 1,
            resource: '',
            expDetailId: e?.expDetailId,
            name: e?.name,
            status: e?.status,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            date: '',
            totalPrice: !isNaN(e.price) ? parseFloat(e.price) : undefined,
            isOpenCurrency: false,
            isOpenTimeZone: false,
            rowKey,
        };

        if (newItem.bundleId) {
            // make bundle unique code to get them together
            // make one row per experience
            const bundleUID = Date.now();
            const newItems = newItem.experiences?.map((exp, idx) => ({
                ...exp,
                experienceId: exp?.experienceId,
                experience: exp?.name,
                experiencer: exp?.experiencerName || exp?.experiencerId,
                experienceDetailId: exp?.experienceId,
                quantity: 1,
                resource: '',
                expDetailId: exp?.expDetailId,
                name: exp?.name,
                status: exp?.status,
                date: '',
                bundleExpPrice: exp.price,
                price: exp.price !== null ? exp.price : exp.defaultPrice,
                totalPrice: exp.price !== null ? exp.price : exp.defaultPrice,
                bundle: newItem.name,
                bundleId: newItem.bundleId,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                isOpenCurrency: false,
                isOpenTimeZone: false,
                rowKey: idx++,
                bundleUID,
            }));
            const auxItems = [...itemsGrid];
            newItems.forEach((it) => auxItems.push(it));
            setItemsGrid(auxItems);
        } else if (newItem?.experienceId) {
            const auxItems = [...itemsGrid];
            auxItems.push(newItem);
            setItemsGrid(auxItems);
        }
    };

    const onRemoveButtonClicked = (row) => {
        const auxItems = itemsGrid.filter((it) => it.rowKey !== row.rowKey);
        setItemsGrid(auxItems);
    };

    const getNameResource = (resource) => {
        if (!resource.length) return '';

        const nameArray = [];

        const resourceNames = rawDataCategories.flatMap((resource) => {
            return resource.resources.map((item) => {
                return { name: item.name, id: item.id };
            });
        });
        resource.map((res) => {
            const valuesToObject = Object.values(res);
            valuesToObject.map((value) => {
                value.map((v) => {
                    const queso = resourceNames.find((r) => r.id === v);
                    nameArray.push(queso);
                });
            });
        });
        return nameArray;
    };
    useEffect(() => {
        if (!selectedExperienceItem) {
            return;
        }

        const namesResources = getNameResource(resourceSelected);
        // TODO: experiencedetailId and expDetailId are the same data
        const newDataFromSelectedSlot = {
            ...selectedExperienceItem,
            experienceId: selectedExperienceItem.experienceId,
            experience: selectedExperienceItem.experience,
            experiencer: selectedExperienceItem.experiencer,
            checkoutId: selectedExperienceItem.id,
            currency: selectedSlot?.currency || selectedExperienceItem?.currency,
            experienceDetailId: selectedExperienceItem?.experienceDetailId,
            quantity: quantity,
            dateFormatted: selectedSlot?.startUTC,
            date: selectedSlot?.startUTC,
            totalPrice: selectedExperienceItem.bundleId && selectedExperienceItem.bundleExpPrice !== null ? parseFloat(selectedExperienceItem.price) * parseInt(quantity || 1) : !isNaN(selectedSlot?.price) ? parseFloat(selectedSlot?.price) * parseInt(quantity || 1) : !isNaN(selectedExperienceItem?.price) ? parseFloat(selectedExperienceItem?.price) * parseInt(quantity || 1) : undefined,
            price: selectedExperienceItem.bundleId && selectedExperienceItem.bundleExpPrice !== null ? selectedExperienceItem.price : !isNaN(selectedSlot?.price) ? selectedSlot?.price : selectedExperienceItem?.price,
            duration: selectedExperienceItem.bundleId && selectedExperienceItem.bundleExpPrice !== null ? selectedExperienceItem.duration : !isNaN(selectedSlot?.duration) ? selectedSlot?.duration : selectedExperienceItem?.duration,
            timezone: selectedSlot?.timezone ? selectedSlot?.timezone : selectedExperienceItem?.timezone,
            resource: namesResources,
            expDetailId: selectedExperienceItem?.experienceDetailId,
            experiencerId: selectedExperienceItem.experiencer,
            name: selectedExperienceItem?.name,
            status: selectedExperienceItem?.status,
            resources: resourceSelected,
        };

        const auxItems = [...itemsGrid];
        if (newDataFromSelectedSlot.rowKey !== undefined) {
            const itemIndex = auxItems.findIndex((it) => it.rowKey === newDataFromSelectedSlot.rowKey);
            auxItems[itemIndex] = newDataFromSelectedSlot;
        } else {
            auxItems.push(newDataFromSelectedSlot);
        }

        setItemsGrid(auxItems);
    }, [selectedSlot, quantity, resourceSelected]);

    useEffect(() => {
        const auxItems = [...itemsGrid];
        setItemsGrid(auxItems);
    }, [overrideAvailability]);

    const getHeaderRow = () => {
        const style = { fontSize: '13px', justifyContent: 'center', fontWeight: 'bold' };
        const hidden = { display: 'none' };
        const headerRow = [
            {
                rowId: 'headerGroup',
                cells: [
                    { type: 'header', text: 'No.', rowspan: 2, style },
                    { type: 'header', text: translate('booking_new.service'), colspan: 2, rowspan: 1, style },
                    { type: 'header', text: '', rowspan: 0, colspan: 0, style: hidden },
                    { type: 'header', text: translate('layout.appBar.touroperator'), rowspan: 2, style },
                    { type: 'header', text: translate('booking_new.resource'), rowspan: 2, style },
                    { type: 'header', text: translate('booking_new.quantity'), rowspan: 2, style },
                    { type: 'header', text: translate('copyScheduleModal.date'), rowspan: 2, style },
                    { type: 'header', text: translate('booking_new.timezone'), rowspan: 2, style },
                    { type: 'header', text: translate('budget.experiences.duration'), rowspan: 2, style },
                    { type: 'header', text: translate('budget.experiences.price'), colspan: 4, rowspan: 1, style },
                    { type: 'header', text: '', rowspan: 0, colspan: 0, style: hidden },
                    { type: 'header', text: '', rowspan: 0, colspan: 0, style: hidden },
                    { type: 'header', text: '', rowspan: 0, colspan: 0, style: hidden },
                    { type: 'header', text: translate('booking_new.remove'), rowspan: 2, style },
                ],
                height: 30,
            },
            {
                rowId: 'header',
                cells: [
                    { type: 'header', text: '', style: hidden },
                    { type: 'header', text: translate('booking_new.bundle'), style },
                    { type: 'header', text: translate('booking_new.experience'), style },
                    { type: 'header', text: '', style: hidden },
                    { type: 'header', text: '', style: hidden },
                    { type: 'header', text: '', style: hidden },
                    { type: 'header', text: '', style: hidden },
                    { type: 'header', text: '', style: hidden },
                    { type: 'header', text: '', style: hidden },
                    { type: 'header', text: translate('booking_new.currency'), style },
                    { type: 'header', text: translate('booking_new.unit_price'), style },
                    { type: 'header', text: translate('booking_new.total_price'), style },
                    { type: 'header', text: translate('booking_new.pay_now'), style },
                    { type: 'header', text: '', style: hidden },
                ],
                height: 45,
            },
        ];
        return headerRow;
    };

    const formatToISOString = (value) => {
        const date = new Date(value);
        return date.toISOString();
    };

    const formatToDateString = (value) => {
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };

    const handleDateChange = (newDate, rowId) => {
        if (newDate) {
            const dateString = formatToISOString(newDate);
            setItemsGrid((prevItems) => {
                return prevItems.map((item) => (item?.rowId === rowId ? { ...item, date: dateString } : item));
            });
        }
    };

    const CustomEditableCell = ({ row }) => {
        const [value, setValue] = React.useState(row.payNow || '');
        const [isEditing, setIsEditing] = React.useState(false);
        const inputRef = React.useRef(null);

        React.useEffect(() => {
            if (isEditing && inputRef.current) {
                inputRef.current.focus();
                inputRef.current.setSelectionRange(0, value.length);
            }
        }, [isEditing]);

        const handleDoubleClick = () => {
            setIsEditing(true);
        };

        const handleBlur = () => {
            setIsEditing(false);
            setItemsGrid((prevItems) => prevItems.map((item) => (item.rowId === row.rowId ? { ...item, payNow: value } : item)));
        };

        const handleKeyDown = (e) => {
            e.stopPropagation();
            if (e.key === 'Enter') {
                handleBlur();
            }

            const arrowKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];
            if (arrowKeys.includes(e.key)) {
                e.stopPropagation();
            }
        };

        return isEditing ? (
            <input
                ref={inputRef}
                type="text"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                onMouseDown={(e) => e.stopPropagation()}
                style={{
                    width: '100%',
                    height: '100%',
                    border: 'none',
                    outline: 'none',
                    padding: '8px',
                    boxSizing: 'border-box',
                    backgroundColor: 'white',
                    position: 'relative',
                    zIndex: 1,
                }}
            />
        ) : (
            <div
                onDoubleClick={handleDoubleClick}
                style={{
                    width: '100%',
                    height: '100%',
                    padding: '8px',
                    cursor: 'pointer',
                    userSelect: 'none',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {value || 'Doble clic para editar'}
            </div>
        );
    };

    const getRows = (values) => {
        return values.map((val, idx) => {
            let resources = '';
            let payNowValue = val?.payNow;
            const originalPayNow = val?.originalPayNow;
            const totalPrice = val?.totalPrice;
            if (val?.payNowType === PayNow.IDS.flexiblePercentage) {
                payNowValue = Math.min(payNowValue, 100);
            } else if (val?.payNowType === PayNow.IDS.flexibleTotal) {
                payNowValue = Math.min(payNowValue, totalPrice);
            }
            payNowValue = Math.max(payNowValue, originalPayNow);
            val.payNow = payNowValue;
            if (val?.resource.length > 0) resources = val?.resource.map((resource) => resource.name).join(', ');
            const style = { justifyContent: 'center', fontSize: '13px', fontWeight: 400 };
            const currencyOptions = [
                { label: 'COP', value: 'COP' },
                { label: 'USD', value: 'USD' },
            ];
            const timeZoneOptions = TIME_ZONES.map((tz) => ({ label: tz.name, value: tz.id }));
            if (val.date === undefined) {
                val.date = '';
            }
            return {
                rowId: idx++,
                cells: [
                    { type: 'text', text: String(idx++), style, nonEditable: true },
                    { type: 'text', text: val?.bundle || '', style: { justifyContent: 'flexStart', fontSize: '13px', fontWeight: 400 }, nonEditable: true },
                    { type: 'text', text: val?.experience || '', style: { justifyContent: 'flexStart', fontSize: '13px', fontWeight: 400 }, nonEditable: true },
                    { type: 'text', text: val?.experiencer, style, nonEditable: true },
                    { type: 'button', text: val?.resource.length > 0 ? resources : 'Select', handleClick: !overrideAvailability ? handleOpenAvailabilityComponent : handleOpenResourcesComponent, exp: val, style: { justifyContent: resources.length > 0 ? 'flexStart' : 'center', fontSize: '13px', fontWeight: 400 }, nonEditable: true },
                    { type: overrideAvailability ? 'number' : 'button', text: String(val?.quantity) || '1', value: val?.quantity, handleClick: handleOpenAvailabilityComponent, exp: val, style, nonEditable: !overrideAvailability },
                    { type: overrideAvailability ? 'datePicker' : 'button', text: val?.date?.length > 0 ? formatToDateString(val?.date) : 'NO DATE' || 'NO DATE', value: val?.date, label: val?.date?.length > 0 ? formatToDateString(val?.date) : '', handleDateChange, handleClick: handleOpenAvailabilityComponent, exp: val, nonEditable: !overrideAvailability },
                    { type: overrideAvailability ? 'dropdown' : 'text', text: val?.timezone || '', values: timeZoneOptions, selectedValue: val?.timezone, isOpen: val?.isOpenTimeZone, style: { justifyContent: 'flexStart', fontSize: '13px', fontWeight: 400 }, nonEditable: !overrideAvailability },
                    { type: 'number', value: val?.duration || null, style, nonEditable: !overrideAvailability },
                    { type: overrideAvailability ? 'dropdown' : 'text', text: val?.currency || '', values: currencyOptions, selectedValue: val?.currency, isOpen: val?.isOpenCurrency, style, nonEditable: !overrideAvailability, handleClick: (e) => e.stopPropagation() },
                    { type: 'number', value: val?.price, style, nonEditable: !overrideAvailability },
                    { type: 'number', value: val?.totalPrice, style, nonEditable: true },
                    {
                        type: val?.payNowType === PayNow.IDS.flexiblePercentage ? 'customCell' : 'number',
                        text: val?.payNowType !== PayNow.IDS.flexiblePercentage ? String(val?.payNow) : '',
                        value: val?.payNowType !== PayNow.IDS.flexiblePercentage ? val?.payNow : undefined,
                        children:
                            val?.payNowType === PayNow.IDS.flexiblePercentage ? (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <CustomEditableCell row={val} />
                                    <span>%</span>
                                </div>
                            ) : undefined,
                        style,
                        nonEditable: !(val?.payNowType === PayNow.IDS.flexiblePercentage || val?.payNowType === PayNow.IDS.flexibleTotal),
                    },
                    { type: 'button', text: '', icon: true, handleClick: onRemoveButtonClicked, exp: val, style: { display: 'flex', alignItems: 'center', justifyContent: 'center' }, nonEditable: true },
                ],
                height: 35,
            };
        });
    };

    const onChange = (changes, prevDetails) => {
        changes.forEach((change) => {
            const dataRowId = change.rowId;
            const fieldName = change.columnId;
            let dataRow = prevDetails.find((d) => d.rowId === dataRowId);
            if (change.type === 'number') {
                dataRow[fieldName] = change?.newCell.value;
                if (fieldName === 'price') {
                    dataRow.totalPrice = change?.newCell.value * dataRow?.quantity;
                } else if (fieldName === 'quantity') {
                    dataRow.totalPrice = dataRow?.price * change?.newCell.value;
                } else if (fieldName === 'payNow') {
                    dataRow.payNow = change?.newCell.value;
                }
            } else if (change.type === 'dropdown') {
                const { selectedValue } = change.newCell;
                if (change.previousCell.selectedValue !== selectedValue) {
                    dataRow[fieldName] = selectedValue;
                }
                if (fieldName === 'timezone') {
                    dataRow.isOpenTimeZone = change.newCell.isOpen;
                } else {
                    dataRow.isOpenCurrency = change.newCell.isOpen;
                }
            }
        });
    };

    const handleChanges = (changes) => {
        setItemsGrid((prevItem) => {
            const updatedItem = [...prevItem];
            onChange(changes, updatedItem);
            return updatedItem;
        });
    };

    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([
        { columnId: 'No', width: 50 },
        { columnId: 'bundle', width: 120 },
        { columnId: 'experience', width: 120 },
        { columnId: 'tourOperator', width: 120 },
        { columnId: 'resource', width: 120 },
        { columnId: 'quantity', width: 80 },
        { columnId: 'date', width: 120 },
        { columnId: 'timezone', width: 120 },
        { columnId: 'duration', width: 80 },
        { columnId: 'currency', width: 80 },
        { columnId: 'price', width: 80 },
        { columnId: 'totalPrice', width: 80 },
        { columnId: 'payNow', width: 80 },
        { columnId: 'removeRow', width: 70 },
    ]);
    const [containerHeight, setContainerHeight] = useState(320);

    const handleMouseDown = (e) => {
        const startY = e.clientY;
        const startHeight = containerHeight;

        const onMouseMove = (e) => {
            const newHeight = Math.max(320, startHeight + (e.clientY - startY));
            setContainerHeight(newHeight);
        };

        const onMouseUp = () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    };

    useEffect(() => {
        if (itemsGrid.some((item) => item.rowId === undefined)) {
            const itemsGridWithRowId = itemsGrid.map((item, index) => (item.rowId === undefined ? { ...item, rowId: index } : item));
            setItemsGrid(itemsGridWithRowId);
        } else {
            if (itemsGrid.length > 0) {
                setRows([...getHeaderRow(), ...getRows(itemsGrid)]);
            } else {
                setRows(getHeaderRow());
            }
        }

        if (itemsGrid.length > 0) {
            let price = 0;
            itemsGrid.forEach((item) => {
                price = item?.totalPrice + price;
                return price;
            });
            setTotalPrice(price);
        } else {
            setTotalPrice(0);
        }
    }, [itemsGrid]);

    const gridContainerRef = useRef(null);

    useLayoutEffect(() => {
        const updateColumnWidths = () => {
            const containerWidth = gridContainerRef.current.clientWidth;
            const minWidths = columns.map((col) => col.width);
            const maxWidths = minWidths.map((width) => width * 2);
            const totalMinWidth = minWidths.reduce((sum, width) => sum + width, 0);
            const totalMaxWidth = maxWidths.reduce((sum, width) => sum + width, 0);

            if (containerWidth <= totalMinWidth) {
                setColumns(columns.map((col, i) => ({ ...col, width: minWidths[i] })));
            } else if (containerWidth >= totalMaxWidth) {
                setColumns(columns.map((col, i) => ({ ...col, width: maxWidths[i] })));
            } else {
                const availableWidth = containerWidth - totalMinWidth;
                const scaleFactor = availableWidth / (totalMaxWidth - totalMinWidth);

                setColumns(
                    columns.map((col, i) => ({
                        ...col,
                        width: minWidths[i] + (maxWidths[i] - minWidths[i]) * scaleFactor,
                    }))
                );
            }
        };

        updateColumnWidths();
        window.addEventListener('resize', updateColumnWidths);
        return () => window.removeEventListener('resize', updateColumnWidths);
    }, [columns.length]);

    const isPaymentPlatformAllowed = (id) => {
        if (!acceptedPaymentPlatforms || !acceptedPaymentPlatforms.length) {
            return false;
        }
        return acceptedPaymentPlatforms.some((method) => method.id === id);
    };

    const handleSubmit = async (values) => {
        const getProcessedDate = (date) => {
            const processDate = new Date(date);
            processDate.setMilliseconds(0);
            return processDate.toISOString();
        };
        const tableData = itemsGrid;
        if (!tableData.length) {
            return;
        }

        const emptyValues = tableData.some((exp) => exp.dateFormatted === '' || exp.currency === null || exp.duration === null);

        if (emptyValues) {
            return toast.error('Checkout not completed.', {
                position: 'top-right',
                theme: 'colored',
            });
        }

        const processedData = tableData.map((item) => {
            return {
                ...item,
                dateUTC: item.date && !isNaN(new Date(item.date).getTime()) ? getProcessedDate(item.date) : '',
            };
        });
        // Separate array in two, experiences and bundles, reduce bundles array and then concat again.
        const expArray = [];
        const bundleMap = {};
        processedData.forEach((data) => {
            if (data.bundleUID) {
                if (bundleMap[data.bundleUID]) {
                    if (!Array.isArray(bundleMap[data.bundleUID].experiences)) {
                        bundleMap[data.bundleUID].experiences = [bundleMap[data.bundleUID].experiences];
                    }
                    bundleMap[data.bundleUID].experiences.push({ ...data });
                } else {
                    bundleMap[data.bundleUID] = {
                        bundleId: data.bundleId,
                        experiences: [{ ...data }],
                    };
                }
            } else {
                expArray.push({ ...data });
            }
        });
        Object.keys(bundleMap).forEach((key) => {
            expArray.push({ ...bundleMap[key] });
        });

        const data = {
            email: values.email,
            firstName: values.firstname,
            lastName: values.lastname,
            // quantity: quantity,
            status: values.status,
            // expId: props.expId,
            // subExpId: props.subExpId,
            // calendarView: props.calendarView,
            mobilePhone: values.mobilePhone,
            mobilePhoneCountryCode: values.mobilePhoneCountryCode,
            additional: values.additional,
            // price: values.price, // Why this!?
            // paidPrice: values.paidPrice,
            hostName: values.hostName,
            // date should be --> 2022-08-20T09:30:00
            // shouldnt the 1st part be the selected date @ calendar?
            // not sure how the service call is received
            // 'props.from.slice(0, idx of T)' --> start date of experience -->
            // 'timeOfArrival' --> HH:mm:ss --> '09:30:00'
            // date: `${props.from.slice(0, props.from.indexOf('T'))}T${timeOfArrival}`,
            // code: contract?.id || null,          // N/A
            code: contract?.id || null,
            paymentPlatform: !isPendingTour ? paymentPlatformId : 0,
            isPendingTour,
            overrideAvailability,
            items: expArray,
        };

        // TODO: we pass expIds because of horrible parsing - REVIEW Later
        const responseBooking = await addBooking('', '', data);

        if (responseBooking.length) {
            toast.error(translate('booking_new.reservation_error'), {
                position: 'top-right',
                theme: 'colored',
            });
            const auxCheckout = [];
            setCheckoutItems(auxCheckout);
            history.push(ROUTES.BOOKINGS.GLOBAL);
        } else {
            if (isPendingTour && responseBooking.bookingId) {
                return history.push(`/booking/${responseBooking.bookingId}`);
            } else if (parseInt(paymentPlatformId) === PaymentPlatform.IDS.link && responseBooking.paymentLink) {
                setPaymentLink(responseBooking.paymentLink);
            }

            setBookingId(responseBooking.bookingId);
            setBookingCode(responseBooking.bookingCode);
            setPaymentId(responseBooking.paymentId);
            setPayNowPrice(responseBooking.payNow);
            setPayTotalPrice(responseBooking.payTotal);
            setCurrency(responseBooking.currency);

            if (parseFloat(responseBooking.payNow) > 0) {
                if (paymentPlatformId === PaymentPlatform.IDS.stripe) {
                    setStripeClientSecret(responseBooking.clientSecret);
                    setStripeOpen(true);
                } else {
                    setOpenPaymentDialog(true);
                    setOpen(true);
                }
            } else {
                if (paymentPlatformId === PaymentPlatform.IDS.link) {
                    setOpenPaymentDialog(true);
                } else {
                    handleModalSuccess(true);
                    setItemsGrid([]);
                }
            }
        }
    };
    const onSubmitConfirmPayment = async (e) => {
        let hideUID = false;
        if (parseInt(paymentPlatformId) === PaymentPlatform.IDS.cash) {
            hideUID = true;
        } else if (parseInt(paymentPlatformId) === PaymentPlatform.IDS.link && bookingId) {
            handleModalSuccess();
        }
        if (!terminalPaymentId && !hideUID) {
            return;
        }
        await addContractBookingPayment({
            paymentId,
            paymentUID: terminalPaymentId,
            status: 'approved',
            statusId: 3,
        });
        handleModalSuccess();
    };
    const cancelPaymentLinkModal = async (e) => {
        // Call api to change status
        await cancelStatusPaymentLink({ paymentLink });
    };
    const onDismissConfirmPayment = async (e) => {
        // Call api to delete payment initiation
        if (!paymentId) {
            return;
        }
        await deleteContractBookingPayment({ paymentId });
    };
    const required = (value) => {
        return value ? undefined : translate('form.required');
    };
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };
    const validatePhone = (value) => (value && /^(?:(?:\+|00)88|01)?\d{11}$/i.test(value) ? undefined : 'Invalid phone number');
    const renderBookingForm = () => {
        return (
            <>
                <Form
                    onSubmit={handleSubmit}
                    // initialValues={{ ...values }}
                    render={({ handleSubmit, values }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            {/* FORM ROWS */}
                            <Grid2 container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                                <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                                    <Field
                                        style={{
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                        required
                                        validate={required}
                                        variant="outlined"
                                        label={translate('bookingForm.firstname')}
                                        name="firstname"
                                        value={values.firstName}
                                        component={TextInput}
                                    />
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                                    <Field
                                        style={{
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                        required
                                        validate={required}
                                        variant="outlined"
                                        label={translate('bookingForm.lastname')}
                                        name="lastname"
                                        value={values.lastName}
                                        component={TextInput}
                                    />
                                </Grid2>
                            </Grid2>
                            <Grid2 container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                                <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                                    <Field
                                        style={{
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                        required
                                        validate={(email) => {
                                            if (!email) {
                                                return translate('form.required');
                                            }
                                            if (!validateEmail(email)) {
                                                return translate('form.invalid.email');
                                            }
                                            return undefined;
                                        }}
                                        onTextFieldChange={(event) => {
                                            setEmail(event?.target.value);
                                        }}
                                        variant="outlined"
                                        label="Email"
                                        name="email"
                                        value={values.email}
                                        component={TextInput}
                                    />
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                                    <Field id="phone" name="mobilePhone" component={PhoneNumber} hintText={translate('profile.phone')} label={translate('profile.phone')} validateField={validatePhone} />
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                                    <Field
                                        style={{
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                        variant="outlined"
                                        label={translate('bookingForm.hostname')}
                                        name="hostName"
                                        component={TextInput}
                                    />
                                </Grid2>
                            </Grid2>
                            <Grid2 container spacing={2} direction="row" justifyContent="flex-end" alignItems="center" sx={{ marginTop: '8px' }}>
                                <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                                    <Field
                                        style={{
                                            marginBottom: '2em',
                                            width: '100%',
                                        }}
                                        id="additional"
                                        name="additional"
                                        component={'textarea'}
                                        label={'Additional Comments'}
                                        placeholder={'Extra info'}
                                        multiline
                                        rows={6}
                                    />
                                </Grid2>
                            </Grid2>

                            {/* BUTTONS */}
                            {totalPrice > 0 && (
                                <Grid2 hidden={values.isPendingTour} container justifyContent="flex-end" spacing={2} style={{ paddingTop: '15px' }}>
                                    <Grid2>
                                        <Button
                                            onClick={() => {
                                                setIsPendingTour(true);
                                                setPaymentPlatformId(null);
                                            }}
                                            variant="contained"
                                            style={{
                                                backgroundColor: isPendingTour ? '#4CAF50' : '#277E2B',
                                                color: '#fff',
                                            }}
                                        >
                                            {translate('form.is_pending_tour')}
                                        </Button>
                                    </Grid2>

                                    {Object.values(PaymentPlatform.IDS).map(
                                        (platformId) =>
                                            isPaymentPlatformAllowed(platformId) && (
                                                <Grid2 key={platformId}>
                                                    <Button
                                                        onClick={() => {
                                                            setPaymentPlatformId(platformId);
                                                            setIsPendingTour(false);
                                                        }}
                                                        variant="contained"
                                                        style={{
                                                            backgroundColor: paymentPlatformId === platformId ? '#4CAF50' : '#277E2B',
                                                            color: '#fff',
                                                        }}
                                                    >
                                                        {PaymentPlatform.getTranslatedName(PaymentPlatform.types, platformId, i18n.language)}
                                                    </Button>
                                                </Grid2>
                                            )
                                    )}
                                </Grid2>
                            )}

                            <Grid2 container spacing={3} justifyContent="flex-end" style={{ marginTop: '25px' }}>
                                <Grid2>
                                    <Button
                                        variant="contained"
                                        type="button"
                                        onClick={(event) => {
                                            const auxCheckout = [];
                                            setItemsGrid(auxCheckout);
                                            history.goBack();
                                        }}
                                    >
                                        {translate('trip.create.buttons.cancel')}
                                    </Button>
                                </Grid2>
                                {/* <Grid2 hidden={!paymentMethodSelected && !isPendingTour}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        // disabled={showErrorPrice}
                                        type="submit"
                                    >
                                        {translate('trip.create.buttons.reserve')}
                                    </Button>
                                </Grid2> */}
                                <Grid2 hidden={!(itemsGrid.length > 0) || (!isPaymentPlatformAllowed(paymentPlatformId) && !paymentMethodSelected && !isPendingTour && totalPrice !== 0)}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        // disabled={showErrorPrice}
                                        type="submit"
                                    >
                                        {translate('trip.create.buttons.reserve')}
                                    </Button>
                                </Grid2>
                            </Grid2>
                        </form>
                    )}
                />
                <Dialog
                    open={openPaymentDialog}
                    dialogTitle={'Booking Payment'}
                    dialogContent={
                        parseInt(paymentPlatformId) === PaymentPlatform.IDS.link && paymentLink
                            ? PaymentLinkModal({
                                  email,
                                  setEmail,
                                  paymentData: {
                                      payTotalPrice,
                                      payNowPrice,
                                      currency,
                                      paymentLink: `${process.env.REACT_APP_UTRIPER_BUY_URL}/?code=${paymentLink}`,
                                  },
                                  translate,
                                  setTerminalPaymentId,
                              })
                            : renderPaymentDialog()
                    }
                    handleClose={() => {
                        parseInt(paymentPlatformId) === PaymentPlatform.IDS.link && paymentLink ? cancelPaymentLinkModal() : onDismissConfirmPayment();
                        setOpenPaymentDialog(false);
                    }}
                    handleSubmit={onSubmitConfirmPayment}
                    labelBack="Cancel"
                    labelNext="Confirm"
                />
                <Dialog
                    open={stripeOpen}
                    dialogTitle={'Booking Confirmation'}
                    hideTitle={true}
                    dialogContent={<CheckoutForm open={stripeOpen} stripePromise={stripePromise} clientSecret={stripeClientSecret} />}
                    handleClose={() => {
                        // DELETE BOOKING!!!
                        // setExpToDelete(null);
                        onDismissConfirmPayment();
                        setStripeOpen(false);
                    }}
                    handleSubmit={() => {
                        // setSuccessOpen(false);
                        // const auxCheckout = [];
                        // setCheckoutItems(auxCheckout);
                        // history.push(`${ROUTES.BOOKINGS}/${bookingId || ''}`);
                    }}
                    labelBack="Cancel"
                    hideBack={false}
                    labelNext="Ok"
                    hideSubmit={true}
                />
            </>
        );
    };

    const renderPaymentDialog = () => {
        let hideUID = false;
        if (parseInt(paymentPlatformId) === PaymentPlatform.IDS.cash) {
            hideUID = true;
        }
        return (
            <div>
                <Grid2 container>
                    <Grid2 size={{ xs: 12, sm: 8, md: 8, lg: 8, xl: 8 }}> {translate('booking_new.amount')} </Grid2>
                    <Grid2 container justifyContent="flex-end" size={{ xs: 12, sm: 4, md: 4, lg: 4, xl: 4 }}>
                        {' '}
                        {currency} {MoneyFormattedUtils(payTotalPrice)}{' '}
                    </Grid2>
                </Grid2>
                <Grid2 container style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <Grid2 size={{ xs: 12, sm: 8, md: 8, lg: 8, xl: 8 }}> {translate('booking_new.amount')} </Grid2>
                    <Grid2 container justifyContent="flex-end" size={{ xs: 12, sm: 4, md: 4, lg: 4, xl: 4 }}>
                        {' '}
                        {currency} {MoneyFormattedUtils(payNowPrice)}{' '}
                    </Grid2>
                </Grid2>
                <div hidden={hideUID}>
                    <Grid2 container>
                        <Grid2 size={12}>
                            <Form
                                onSubmit={() => {}}
                                // initialValues={{ ...values }}
                                render={({ handleSubmit, values }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Field
                                            required
                                            // validate={required}
                                            className="add-experience__capacity"
                                            variant="outlined"
                                            label={translate('booking_new.post_code')}
                                            name="paymentId"
                                            value={''}
                                            onTextFieldChange={(e) => {
                                                const { name, value } = e.target;
                                                setTerminalPaymentId(value);
                                            }}
                                            component={TextInput}
                                        />
                                    </form>
                                )}
                            />
                        </Grid2>
                    </Grid2>
                </div>
            </div>
        );
    };

    return (
        <Grid2 container translate="no">
            <Grid2 container spacing={2}>
                <Grid2 size={12}>
                    <Title value={translate('booking_new.summary')} />
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 3 }}>
                    <SelectComponent label={translate('booking_new.add_contract')} emptyValue emptyLabel={translate('booking_new.without_contract')} values={totalContracts} onChange={(e) => handleSelectContract(e)} onEmpty={() => handleSelectContract()} />
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 3 }}>
                    <CheckoutItemSelect values={totalItems} onChange={(e) => handleSelectedExperience(e)} />
                </Grid2>
                <Grid2 size={12}>
                    <Box sx={{ width: '100%', height: containerHeight, overflowY: 'auto', border: '1px solid #ddd', borderRadius: 2, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Box sx={{ width: '100%' }} ref={gridContainerRef}>
                            <DataGrid rows={rows} columns={columns} handleChange={handleChanges} stickyLeftColumns={1} />
                        </Box>
                        {itemsGrid?.length <= 0 && (
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'sticky',
                                    top: '50%',
                                    right: 0,
                                    color: '#ccc',
                                    fontWeight: 600,
                                }}
                            >
                                <Typography variant="subtitle1" sx={{ fontSize: '13px' }}>
                                    {translate('booking_new.no_data')}
                                </Typography>
                            </Box>
                        )}
                        <Box
                            sx={{
                                width: '100%',
                                height: '32px',
                                backgroundColor: 'transparent',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                borderTop: '.5px solid #eee',
                                marginTop: 'auto',
                                position: 'sticky',
                                left: 0,
                                right: 0,
                            }}
                        >
                            <Typography variant="subtitle1" sx={{ padding: '12px', fontSize: '13px' }}>
                                TOTAL: {totalPrice}
                            </Typography>
                        </Box>
                        <Box
                            onMouseDown={handleMouseDown}
                            sx={{
                                width: '100%',
                                height: '20px',
                                cursor: 'ns-resize',
                                backgroundColor: '#f0f0f0',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderTop: '1px solid #ccc',
                                position: 'sticky',
                                left: 0,
                                right: 0,
                            }}
                        >
                            <DragIndicatorIcon fontSize="small" sx={{ color: '#666' }} />
                        </Box>
                    </Box>
                </Grid2>
                <Grid2
                    style={{
                        display: 'flex',
                        flexDirection: 'flex-start',
                    }}
                >
                    <Tooltip placement="right" title={translate('form.override_availability_checkbox')}>
                        <CheckboxComponent
                            labelComponent={'Override Availability'}
                            checked={overrideAvailability}
                            onChange={(value) => {
                                setOverrideAvailability(value);
                                if (itemsGrid?.length > 0) {
                                    const auxItems = itemsGrid?.map((it) => {
                                        return { ...it, date: '' };
                                    });
                                    setItemsGrid([...auxItems]);
                                }
                            }}
                        />
                    </Tooltip>
                </Grid2>
                <Grid2 size={12}>{renderBookingForm()}</Grid2>
            </Grid2>
        </Grid2>
    );
};

export default CheckoutPageComponent;
