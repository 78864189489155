import {
  InputLabel,
  Select,
  Checkbox,
  FormControl,
  MenuItem,
  ListItemText,
  OutlinedInput,
} from '@mui/material';
import React from 'react';

const SelectCheckbox = ({
  options,
  label,
  onChange,
  id,
  values,
  labelId,
  hasError,
}) => {
  const isChecked = (optionId) => {
      return values.some((selected) => selected.id === optionId);
  };
  const handleSelect = (option) => {
      const isSelected = values.some((selected) => selected.id === option.id);
      if (isSelected) {
          const newValues = values.filter(
              (selected) => selected.id !== option.id
          );
          onChange(newValues);
      } else {
          onChange([...values, option]);
      }
  };

  const renderItem = (option) => {
      const checked = isChecked(option.id);
      return (
          <MenuItem
              key={option.id}
              value={option.id}
              onClick={() => handleSelect(option)}
              style={{ whiteSpace: 'break-spaces' }}>
              <Checkbox checked={checked} />
              <ListItemText
                  primary={option.name}
                  style={{ color: 'black', wordBreak: 'break-word' }}
              />
          </MenuItem>
      );
  };

  return (
      <FormControl error={hasError}>
          <InputLabel id={labelId}>{label}</InputLabel>
          <Select
              input={<OutlinedInput label={label} />}
              id={id}
              multiple
              labelId={labelId}
              value={values.map((v) => v.id) || []}
              renderValue={(selected) => {
                  return options
                      .filter((option) => selected.includes(option.id))
                      .map((option) => option.name)
                      .join(' , ');
              }}
              MenuProps={{
                  PaperProps: {
                      style: {
                          maxHeight: '20rem',
                          width: '20rem',
                      },
                  },
              }}>
              {options.map(renderItem)}
          </Select>
      </FormControl>
  );
};

export default SelectCheckbox;
